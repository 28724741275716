import Web3 from 'web3'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import connectWalletModalService from './connectWalletModalService'
import { CONST } from '../config'
import axios from 'axios'

var check = false
toast.configure()
export const ChangeNetwork = {
	networkChange: async () => { },

	Ethnetwork: async () => {
		if (typeof window.web3 !== 'undefined') {
			try {
				await window.ethereum
					.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: '0x1' }]
					})
					.then(() => {
						const sucMsg = {
							status: true,
							message: 'Correct Network'
						}
					})
			} catch (switchError) {
				if (switchError.code === 4902) {
					try {
						check = await window.ethereum
							.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: '0x1' }]
							})
							.then(() => {
								return true
							})
							.catch(err => {
								check = false
								return check
							})
						return check
					} catch (switchError) {
						if (switchError.code === 4902) {
							try {
								await window.ethereum
									.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainId: '0x1',
												chainName: 'Ethereum Mainnet',
												blockExplorerUrls: [
													'https://etherscan.io'
												],
												nativeCurrency: {
													name: 'Ether',
													decimals: 18,
													symbol: 'ETH'
												},
												rpcUrls: [
													'https://mainnet.infura.io/v3/'
												]
											}
										]
									})
									.then(() => {
										sessionStorage.setItem('chainId', '1')
									})
							} catch (addError) { }
						}
					}
				}
			}
		}
	},

	Bnbnetwork: async () => {
		if (typeof window.web3 !== 'undefined') {
			try {
				await window.ethereum
					.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: '0x38' }]
					})
					.then(() => {
						const sucMsg = {
							status: true,
							message: 'Correct Network'
						}
					})
			} catch (switchError) {
				console.log(switchError)
				if (switchError.code === 4902) {
					console.log('switchError')
					try {
						await window.ethereum
							.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: '0x38',
										chainName:
											'BSC Smart Chain Mainnet',
										blockExplorerUrls: [
											'https://bscscan.com/'
										],
										nativeCurrency: {
											name: 'BNB',
											decimals: 18,
											symbol: 'BNB'
										},
										rpcUrls: [
											'https://endpoints.omniatech.io/v1/bsc/mainnet/public'
										]
									}
								]
							})
							.then(() => {
								sessionStorage.setItem('chainId', '56')
								connectWalletModalService.sendMessage(
									'fetch'
								)
							})
					} catch (addError) { 
						console.log(addError)
					}
				}
			}
		}
	},

	switchToEthereum: async () => {
		const provider = localStorage.getItem('provider')
		const web3Instance = new Web3(provider)
		const chainId = await web3Instance.eth.chainId()

		if (chainId === 56) {
			provider.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: '0x1' }]
			})
		}
	}
}

export const notify = {
	walletconnect: async () => {
		toast.error('Please Login to Continue')
	}
}

export const accountChange = {
	changeAccount: async () => {
		try {
			if (window.ethereum) {
				window.ethereum.on('accountsChanged', async function (accounts) {
					if (accounts.length == 0) {
						setTimeout(() => {
							window.location.reload()
						}, 1000)
						return
					} else {
						const address = accounts[0]
						const refLink = sessionStorage.getItem('refId')
						let getAccount = await axios.post(
							CONST.BACKEND_URL + '/ico/connectWallet',
							{ address, refLink }
						)

						if (getAccount.data.status == 200) {
							toast.success('Wallet Connected')
							sessionStorage.setItem(
								'bigShotAccount',
								accounts[0]
							)

							setTimeout(() => {
								window.location.reload()
							}, 1000)
						}
					}
				})
			} else {
			}
		} catch (err) {
		 }
	}
}

export const networkChange = {
	changenetwork: async () => {
		try {
			if (window.ethereum) {
				window.ethereum.on(
					'networkChanged',
					async function (networkId) {
						if (networkId != 56 && networkId != 1) {
							ChangeNetwork.Bnbnetwork()
						} else {
							sessionStorage.setItem('chainId', networkId)
							connectWalletModalService.sendMessage('fetch')
						}
					}
				)
			} else {
			}
		} catch (err) { }
	}
}

export const connectAccount = {
	accountConnect: async () => { }
}
