const loading = false;
const reducer = (state={loading}, action) =>{
	switch(action.type){
		case 'SET_LOADING':
		return {...state,loading:true}
		case 'GET_ALL_USERS_SUCCESS':
		return {...state,loading:false,allUsers: action.responseData }
		default:
			return state;

	}
}
export default reducer