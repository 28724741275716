import React, { useState, useEffect, Component, Fragment, useRef } from 'react'
import {
	Collapse,
	Nav,
	NavItem,
	Modal,
	Navbar,
	NavbarToggler,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ModalBody,
	ModalHeader
} from 'reactstrap'
import {
	Route,
	Link,
	BrowserRouter as Router,
	useNavigate,
	NavLink,
	useParams,
	useLocation
} from 'react-router-dom'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import axios from 'axios'
import WalletConnectProvider from '@walletconnect/web3-provider'

import { ChangeNetwork, accountChange, networkChange } from '../common/Services'
import { CONST } from '../config'

import logo from '../assets/images/logo.svg'
import fav from '../assets/images/favicon.svg'
import metamaskImg from '../assets/images/metamask.svg'
import trustWalletImg from '../assets/images/trst-wll.png'

import walletImg from '../assets/images/wallet.svg'
import copy from '../assets/images/copy.png'
import connectWalletModalService from '../common/connectWalletModalService'
import '../common/Header/scss/header.scss'

import { buyBigShotToken } from '../common/Servicecontract'

const Header = ({
	handleHomeBtn,
	handleWhitePaperBtn,
	handleRoadMaprBtn,
	handleContactUsBtn,
	whitePaperRef,
	refId
}) => {

	const { id } = useParams()

	useEffect(() => {
		const subscription = connectWalletModalService
			.getMessage()
			.subscribe(({ component }) => {
				if (component === 'open') {
					toggle1()
				} else if (component === 'close') {
					toggle1()
				} else if (component === 'fetch') {
					fetchBalances()
				}else if(component === 'user_referral'){
					toggle1()

				}
			})
		return () => {
			subscription.unsubscribe()
		}
	}, [])

	const [classIsActive, setClassIsActive] = useState(true)
	const [isMob, setIsMob] = useState(false)
	const sideMenu = () => {
		setClassIsActive(!classIsActive)
		classIsActive
			? document.body.classList.add('sbMenu')
			: document.body.classList.remove('sbMenu')
		setIsMob(!isMob)
	}

	const [scroll, setScroll] = useState(false)
	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50)
		})
	}, [])

	toast.configure()

	const navigate = useNavigate()
	const [metaaccount, setmetaaccount] = useState(
		sessionStorage.getItem('account')
	)
	const [metabalance, setmetabalance] = useState(0)
	const [profile, setProfileData] = useState('')
	const [metachainId, setmetachainId] = useState(
		sessionStorage.getItem('chainId')
	)
	const [loading, setLoading] = useState(false)
	const [status, setStatus] = useState(false)
	const [maintananceDetail, setMaintananceDetail] = useState('')

	const [balances, setBalances] = useState({
		BNB: '',
		ETH: '',
		USDT: '',
		BTYC: '',
		BSBT: ''
	})

	const [modal, setModal] = useState(false)

	const [detailmodal, setDetailModal] = useState(false)
	const detailToggle = () => {
		fetchBalances()
		setDetailModal(!detailmodal)
	}

	const toggle = () => setModal(!modal)

	const [modal1, setModal1] = useState(false)
	const toggle1 = () => {
		setModal1(!modal1)
	}

	const [modal2, setModal2] = useState(false)
	const toggle2 = () => {
		setModal2(!modal2)
		setModal1(false)
		ChangeNetwork.networkChange()
	}
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const toggle3 = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const [colorChange, setColorchange] = useState(false)
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setColorchange(true)
		} else {
			setColorchange(false)
		}
	}
	window.addEventListener('scroll', changeNavbarColor)
	let web3

	useEffect(() => {
		if (sessionStorage.getItem('bigshotloginId')) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		getSiteInform()
		accountChange.changeAccount()
		networkChange.changenetwork()
	}, [])

	useEffect(() => {
		fetchBalances()
	}, [sessionStorage.getItem('bigShotAccount')])

	async function fetchBalances() {
		const res = await axios.post(
			CONST.BACKEND_URL + '/users/walletBalance',
			{ address: sessionStorage.getItem('bigShotAccount') }
		)

		if (res.data.status === 200) {
			setBalances({
				...balances,
				BNB: res.data.data.bnb_balance,
				ETH: res.data.data.eth_balance,
				USDT: res.data.data.usdt_balance,
				BTYC: res.data.data.btyc_balance,
				BSBT: res.data.data.bsbt_balance
			})
		}
	}
	const styles = {
		color: '#5AC41E'
	}

	const fetchData = async () => {}

	async function connectWallet(type) {
		let a = getMobileOperatingSystem()

		if (a == 'Android' || a == 'iOS') {
			if (window.ethereum) {
				if (typeof window !== undefined) {
					web3 = new Web3(window.ethereum)
					if (typeof window.web3 !== undefined) {
						try {
							await window.ethereum.enable()

							const chainId = await web3.eth.getChainId()
							sessionStorage.setItem('chainId', chainId)
							await ChangeNetwork.Bnbnetwork()
							const accounts = await web3.eth.getAccounts()
							sessionStorage.setItem(
								'bigShotAccount',
								accounts[0]
							)
							const balance = await web3.eth.getBalance(
								accounts[0]
							)
							localStorage.setItem('wallet', type)
							let address = accounts[0],
								balanceWallet = balance / Math.pow(10, 18)

							let first = accounts[0].substring(0, 3)
							let last = accounts[0].substring(38, 42)
							let shortAccount = `${first}...${last}`

							setmetaaccount(address)
							setmetabalance(balance)
							setmetachainId(chainId)
							setModal1(false)

							let getAccount = await axios.post(
								CONST.BACKEND_URL + '/ico/connectWallet',
								{ address: accounts[0], refLink: refId }
							)

							if (getAccount.data.status == 200) {
								toast.success('Wallet Connected')
								sessionStorage.setItem(
									'bigShotAccount',
									accounts[0]
								)

								setTimeout(() => {
									window.location.reload()
								}, 1000)
							}
						} catch (error) {}
					}
				}
			} else {
				if (type === 'Metamask') {
					let a = getMobileOperatingSystem()
					if (a == 'Android') {
						const dappUrl = 'https://bigshot.games/'
						let metamaskAppDeepLink =
							'https://metamask.app.link/dapp/' + dappUrl
						let a = window.open(metamaskAppDeepLink, '_self')
						if (a) {
							window.close()
						} else {
							toast.error('Install Metamask')
						}

						// return false;
					} else if (a == 'iOS') {
						const dappUrl = 'https://bigshot.games/'
						let metamaskAppDeepLink =
							'https://metamask.app.link/dapp/' + dappUrl
						let a = window.open(metamaskAppDeepLink, '_self')

						if (a) {
							window.close()
						} else {
							toast.error('Install Metamask')
						}
					} else {
						toast.error('Install Metamask')
					}
				} else {
					let a = getMobileOperatingSystem()
					if (a == 'Android') {
						const dappUrl = 'https://bigshot.games/'
						let metamaskAppDeepLink = `https://link.trustwallet.com/open_url?coin_id=56&url=${dappUrl}}`
						let a = window.open(metamaskAppDeepLink, '_self')
						if (a) {
							window.close()
						} else {
							toast.error('Install Trust Wallet')
						}

						// return false;
					} else if (a == 'iOS') {
						const dappUrl = 'https://bigshot.games/'
						let metamaskAppDeepLink = `https://link.trustwallet.com/open_url?coin_id=56&url=${dappUrl}}`
						let a = window.open(metamaskAppDeepLink, '_self')

						if (a) {
							window.close()
						} else {
							toast.error('Install Trust Wallet')
						}
					} else {
						toast.error('Install Trust Wallet')
					}
				}
			}
		} else if (type === 'Metamask') {
			if (typeof window !== undefined) {
				web3 = new Web3(window.ethereum)
				if (typeof window.web3 !== undefined) {
					try {
						await window.ethereum.enable()
						const chainId = await web3.eth.getChainId()
						sessionStorage.setItem('chainId', chainId)
						await ChangeNetwork.Bnbnetwork()
						const accounts = await web3.eth.getAccounts()
						sessionStorage.setItem('bigShotAccount', accounts[0])
						const balance = await web3.eth.getBalance(accounts[0])
						let address = accounts[0],
							balanceWallet = balance / Math.pow(10, 18)
						localStorage.setItem('wallet', type)

						let first = accounts[0].substring(0, 3)
						let last = accounts[0].substring(38, 42)
						let shortAccount = `${first}...${last}`

						setmetaaccount(address)
						setmetabalance(balance)
						setmetachainId(chainId)
						setModal1(false)

						let getAccount = await axios.post(
							CONST.BACKEND_URL + '/ico/connectWallet',
							{ address: accounts[0], refLink: refId }
						)

						if (getAccount.data.status == 200) {
							toast.success('Wallet Connected')
							sessionStorage.setItem(
								'bigShotAccount',
								accounts[0]
							)

							setTimeout(() => {
								console.log('timeout')
								window.location.reload()
							}, 1000)
						}
					} catch (error) {
						console.log(error)
					}
				}
			}
		} else {
			try {
				const provider = new WalletConnectProvider({
					rpc: {
						56: 'https://bsc-dataseed1.ninicoin.io',
						1: 'https://mainnet.infura.io/v3/'
					},
					qrcode: true
					// qrcodeModalOptions: {
					// 	mobileLinks: ['trust']
					// }
				})
				web3 = new Web3(provider)
				await provider.enable().then(async accounts => {
					setModal1(false)
					let walletconnect = localStorage.getItem('walletconnect')
					walletconnect = JSON.parse(walletconnect)
					setmetaaccount(accounts[0])
					setmetachainId(walletconnect.chainId)
					sessionStorage.setItem('chainId', walletconnect.chainId)
					sessionStorage.setItem('bigShotAccount', accounts[0])
					localStorage.setItem('wallet', type)

					let getAccount = await axios.post(
						CONST.BACKEND_URL + '/ico/connectWallet',
						{ address: accounts[0], refLink: refId }
					)

					if (getAccount.data.status == 200) {
						toast.success('Wallet Connected')
						sessionStorage.setItem('bigShotAccount', accounts[0])

						// setTimeout(() => {
						// 	window.location.reload()
						// }, 1000)
					}

					provider.on('accountsChanged', accounts => {
						setmetaaccount(accounts[0])
						sessionStorage.setItem('bigShotAccount', accounts[0])
					})

					// Subscribe to chainId change
					provider.on('chainChanged', chainId => {
						setmetachainId(walletconnect.chainId)
						sessionStorage.setItem('chainId', chainId)
					})
				})
			} catch (err) {}
		}
	}

	const getMobileOperatingSystem = () => {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone'
		}

		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}

		return 'unknown'
	}

	const copyClipboard = () => {
		navigator.clipboard.writeText(sessionStorage.getItem('bigShotAccount'))
		toast.success('Address Copied')
	}

	const logout = () => {
		sessionStorage.removeItem('bigshotloginId')
		sessionStorage.removeItem('bigshotemail')
		localStorage.removeItem('bigshotemail')
		sessionStorage.removeItem('referralcode')
		localStorage.removeItem('bigshotloginId')
		window.location.reload()
	}
	const disconnectWallet = async () => {
		if (!sessionStorage.getItem('bigShotAccount')) {
		} else {
			let getAccount = await axios.post(
				CONST.BACKEND_URL + '/ico/disconnectWallet',
				{ address: sessionStorage.getItem('bigShotAccount') }
			)

			if (getAccount.data.status == 200) {
				sessionStorage.removeItem('bigShotAccount')
				sessionStorage.removeItem('walletType')
				sessionStorage.removeItem('balance')
				sessionStorage.removeItem('chainId')
				sessionStorage.removeItem('wallet')
				sessionStorage.removeItem('refId')
				toast.success('Wallet Disconnected')
			}
		}

		setModal1(false)
		setDetailModal(false)
		setTimeout(() => {
			document.location.href = '/'
		}, 1000)
	}

	const getSiteInform = async () => {
		let getData = await axios.get(CONST.BACKEND_URL + '/ico/site_inform')

		if (getData.data.status === true) {
			setMaintananceDetail(getData.data.data.site_logo)
		}
	}

	const navigateToHome = () => {
		navigate('/')
	}

	const { pathname } = useLocation()

	
	return (
		<Fragment>
			<header class={scroll ? 'indHd scrolled' : 'indHd px-0'}>
				<div class='indHdTp'>
					<div class='container'>
						<nav class='navbar navbar-expand-lg px-0'>
							<Link
								class='navbar-brand'
								to='/'
								onClick={() =>
									connectWalletModalService.sendMessage(
										'scroll-to-top'
									)
								}
							>
								<img
									src={logo}
									alt='logo'
									class='d-none d-sm-inline-block'
								/>
								<img
									src={logo}
									alt='logo'
									class='d-inline-block d-sm-none'
									style={{ width: '50px' }}
								/>
							</Link>

							<div class='hdNavMenu'>
								<div
									class='collapse_rn navbar-collapse'
									id='indHdClp'
								>
									<ul class='navbar-nav'>
										<li class='nav-item'>
											<Link
												class='nav-link'
												to='/'
												onClick={() =>
													connectWalletModalService.sendMessage(
														'scroll-to-top'
													)
												}
											>
												Home
											</Link>
										</li>
										<li class='nav-item'>
											<Link
												class='nav-link'
												to='/'
												state={{ previousPath: pathname }}
												onClick={() => {
													connectWalletModalService.sendMessage(
														'whitepaper'
													)
												}}
											>
												White Paper
											</Link>
										</li>
										<li class='nav-item'>
											<Link
												class='nav-link'
												to='/'
												state={{ previousPath: pathname }}
												onClick={() => {
													connectWalletModalService.sendMessage(
														'roadmap'
													)
												}}
											>
												Roadmap
											</Link>
										</li>

										<li class='nav-item'>
											<Link
												class='nav-link'
												to='/referral'
											>
												Referral
											</Link>
										</li>
										
										<li class='nav-item'>
											<Link
												class='nav-link'
												to='/contact-us'
												onClick={() => {
													connectWalletModalService.sendMessage(
														'scroll-to-top'
													)
												}}
											>
												Contact Us
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div class='hdRt'>
								{!sessionStorage.getItem('bigShotAccount') || sessionStorage.getItem('bigShotAccount') === 'undefined'  ? (
									<Link
										class='btn btn-15538 whitepaper-btn'
										onClick={toggle1}
									>
										Connect Wallet
									</Link>
								) : (
									<Link
										className='btn btn-15538 whitepaper-btn ms-2 ms-md-2 pt-2'
										onClick={detailToggle}
									>
										{sessionStorage.getItem(
											'bigShotAccount'
										) ? (
											sessionStorage
												.getItem('bigShotAccount')
												.slice(0, 4) +
											'...' +
											sessionStorage
												.getItem('bigShotAccount')
												.slice(38, 42)
										) : (
											<></>
										)}
									</Link>
								)}
								<div class='mobMenu d-lg-none'>
									<div
										class={
											isMob ? 'smClose active' : 'smClose'
										}
										onClick={sideMenu}
									>
										<svg
											id='closeicon'
											viewBox='0 0 800 600'
										>
											<path
												d='M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200'
												id='top'
											></path>
											<path
												d='M300,320 L460,320'
												id='middle'
											></path>
											<path
												d='M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190'
												id='bottom'
												transform='translate(480, 320) scale(1, -1) translate(-480, -318) '
											></path>
										</svg>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</header>

			<Modal
				isOpen={modal}
				toggle={toggle}
				className='cnwmdl modal-dialog-centered'
			>
				<ModalHeader toggle={toggle}>Disconnect Wallet</ModalHeader>

				<ModalBody>
					<div className='cnwdt'>
						<img src={metamaskImg} className='d-block mx-auto' />
						<p>Meta Mask</p>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={modal1}
				toggle={toggle1}
				className='dcnwmdl modal-dialog-centered'
			>
				<ModalBody>
					<div
						className='dcnwdt mb-3'
						onClick={() => connectWallet('Metamask')}
						style={{ cursor: 'pointer' }}
					>
						<img src={metamaskImg} className='d-block mx-auto' />
						<p>Meta Mask</p>
					</div>
					<div
						className='dcnwdt'
						onClick={() => connectWallet('Trust')}
						style={{ cursor: 'pointer' }}
					>
						<img src={trustWalletImg} className='d-block mx-auto' />
						<p>Trust Wallet</p>
					</div>
					{/* {sessionStorage.getItem('bigShotAccount') ? (
						<>
							<h5>
								<img src={walletImg} />
								<span className='ms-2'>
									{sessionStorage
										.getItem('bigShotAccount')
										.slice(0, 8) +
										'.....' +
										sessionStorage
											.getItem('bigShotAccount')
											.slice(32, 42)}
								</span>
								<span
									className='cpy_btn p-2 copybtn'
									id='emailHelp'
								>
									<img src={copy} onClick={copyClipboard} />
								</span>
							</h5>
							<button
								className='btn btn-gold btn-15540 mx-auto d-block'
								onClick={disconnectWallet}
							>
								Disconnect
							</button>
						</>
					) : (
						<></>
					)} */}
				</ModalBody>
			</Modal>

			<Modal
				isOpen={modal2}
				toggle={toggle2}
				className='cnwmdl modal-dialog-centered'
			>
				<ModalHeader toggle={toggle2}>Disconnect Wallet</ModalHeader>
				<ModalBody>
					<div className='cnwdt' onClick={disconnectWallet}>
						<img src={metamaskImg} className='d-block mx-auto' />
						<p>Meta Mask</p>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={detailmodal}
				toggle={detailToggle}
				className='cnwmdl modal-dialog-centered'
			>
				<ModalHeader className='modal-header' toggle={detailToggle}>
					My Wallet
				</ModalHeader>

				<ModalBody>
					<div className='WltTknHdd mb-3'>
						<h4>
							{sessionStorage.getItem('chainId') == '56'
								? 'Binance Smart Chain (BSC)'
								: 'Ethereum'}
						</h4>
					</div>
					<div className='WltTknBg mb-4'>
						<div className='WltTknCnt'>
							<p>Balances</p>
						</div>
						<div className='WltAmntDtls'>
							<h4>
								{balances.BSBT}
								<small style={styles}>BSBT</small>
							</h4>
							<h4>
								{balances.BNB}
								<small style={styles}>BNB</small>
							</h4>
							{/* <h4>
								{balances.ETH}
								<small style={styles}>ETH</small>
							</h4> */}
							<h4>
								{balances.USDT}
								<small style={styles}>USDT</small>
							</h4>
							<h4>
								{balances.BTYC}
								<small style={styles}>BTYC</small>
							</h4>
						</div>
					</div>

					<div className='MyWlltBtnSec'>
						<button
							className='btn whitepaper-btn btn-15538 btn-15540  mx-auto'
							onClick={disconnectWallet}
						>
							Disconnect
						</button>
					</div>
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

export default Header
