import '../Buytoken/scss/buytoken.scss'

import React, { Fragment, useEffect, useState } from 'react'
import metamaskImg from '../../assets/images/metamask.svg'
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	InputGroup,
	InputGroupText,
	Input,
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap'
import refreshImg from '../../assets/images/refresh.svg'
import downarrowImg from '../../assets/images/downarrow.svg'
import usdtImg from '../../assets/images/usdt.svg'
import ethImg from '../../assets/images/eth.svg'
import trxImg from '../../assets/images/trx.svg'
import btcImg from '../../assets/images/bigshot coin 1.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CONST } from '../../config/index'
import { uuid } from 'uuidv4'

import stakeAbi from '../../common/stakeAbi.json'
import Web3 from 'web3'
import '../../App.css'
import { ChangeNetwork, accountChange } from '../../common/Services'
import aes from 'aes256'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ContractDetails from '../../common/Contract'
import stake from '../../common/stakeAbi.json'
import tokenAbi from '../../common/tokenAbi.json'
import { buyBigShotToken } from '../../common/Servicecontract'
import FadeLoader from 'react-spinners/FadeLoader'
import LoadingOverlay from 'react-loading-overlay'

import Header from '../../components/Header'
import connectWalletModalService from '../../common/connectWalletModalService'
import { NumericFormat } from 'react-number-format'

export const Home1 = props => {
	toast.configure()

	let web3
	let getdata
	const [currencyDetails, setCurrencyDetails] = useState('')
	const [currency, setCurrency] = useState('Select Currency')
	const [curSymbol, setCUrSymbol] = useState()
	const [curAmount, setCurAmount] = useState()
	const [usdtPrice, setUsdtPrice] = useState()
	const [currencyAmt, setCurrencyAmt] = useState(0)
	const [bigshotAmt, setBigshotAmt] = useState()
	const [coinAmt, setCoinAmt] = useState()
	const [code, setCode] = useState()
	const [curImg, setcurImg] = useState('')
	const [chainid, setChainId] = useState()
	const [inoutLength, setInputLength] = useState(true)
	const [metaaccount, setmetaaccount] = useState(
		sessionStorage.getItem('account')
	)
	const [metabalance, setmetabalance] = useState(0)
	const [metachainId, setmetachainId] = useState('')
	const [transferType, setTransferType] = useState('centralized')
	const [toAddress, setToAddress] = useState('')
	const [usdtAmount, setUsdtAmount] = useState(0)
	const [bigshotPrice, setBigshotPrice] = useState(0)
	const [getContent, setGetContent] = useState([])
	const [buyBigshotAmt, setMinBigshotBuyAmt] = useState('')
	const [coinBal, setCoinBal] = useState([])
	const [minBuyBigshotAmt, setMinBuyBigshotAmount] = useState('')
	const [spendCurrencyAmt, setSpendCurrencyAmt] = useState('')
	const [walletAddress, setWalletAddress] = useState('')
	const [allCurAmount, setAllCurAmount] = useState([])
	const [loading, setLoading] = useState(false)
	const [color, setColor] = useState('#5AC41E')
	const [siteInform, setSiteinform] = useState([])
	const [maxBuyBigshotAmt, setMaxBuyBigshotAmount] = useState('')
	if (typeof window !== undefined) {
		web3 = new Web3(window.ethereum)
	}

	const stakeContract = new web3.eth.Contract(
		stake,
		ContractDetails.Signer_contract
	)

	const navigate = useNavigate()
	const [modal, setModal] = useState(false)
	const [owner, setOwner] = useState('')
	const [minDolAmt, setminDolAmt] = useState(0)
	const toggle = () => setModal(!modal)

	const [selectedCurrencyValueInUsd, setSelectedCurrencyValueInUsd] =
		useState('')
	const [UsdtPriceforCurrency, setUsdtPriceforCurrency] = useState('')
	const [isDisabled, setIsDisabled] = useState(true)
	const [limit, setLimit] = useState({
		minimum: '',
		dailyLimit: ''
	})
	const [bsbtValue, setBsbtValue] = useState({
		BNB: '',
		ETH: '',
		USDT: '',
		BTYC: ''
	})
	const [curInBsbt, setCurInBsbt] = useState('')

	useEffect(() => {
		getSiteInform()
		fetchData()
		getUsdtData1()
		getBigShotAmount()
		getLimit()

		accountChange.changeAccount()

		getChainId()
	}, [chainid && chainid])

	let config = {
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		}
	}
	useEffect(() => {
		getbuytokencontent()
		window.scrollTo(0, 0)
	}, [])

	async function getLimit() {
		const res = await axios.post(
			CONST.BACKEND_URL + '/users/getBSBTValue',
			{ address: sessionStorage.getItem('bigShotAccount') }
		)
		setLimit({
			...limit,
			minimum: res.data.data.bsbt_minimuBuy,
			dailyLimit: res.data.data.bsbt_dailyLimit
		})
		setMinBuyBigshotAmount(res.data.data.bsbt_minimuBuy)
		setMaxBuyBigshotAmount(res.data.data.bsbt_dailyLimit)
		setBsbtValue({
			...bsbtValue,
			BNB: res.data.data.bsbt_bnb,
			ETH: res.data.data.bsbt_eth,
			USDT: res.data.data.bsbt_usdt,
			BTYC: res.data.data.bsbt_bytc
		})
	}

	const getSiteInform = async () => {
		let getData = await axios.get(CONST.BACKEND_URL + '/ico/site_inform')

		if (getData.data.status === true) {
			setminDolAmt(getData.data.data.min_amount)
			setSiteinform(getData.data.data)
			getUsdtData1()
		}
	}

	const getBigShotAmount = async () => {
		let getBigShotAmount = await axios.get(
			CONST.BACKEND_URL + '/ico/getallAmount'
		)

		if (getBigShotAmount.data.status == true) {
			setAllCurAmount(getBigShotAmount.data.data)
		}
	}

	const getBalance = async data => {
		let data1 = {
			user_id: sessionStorage.getItem('bigShotAccount')
		}
		const getBal = await axios.post(
			CONST.BACKEND_URL + '/ico/getbalance',
			data1
		)
		if (getBal && getBal.data.status == 200) {
			let cnBalance = getBal.data.data

			for (let i = 0; i < cnBalance.length - 1; i++) {
				if (data.currency_name == cnBalance[i].currency) {
					setSpendCurrencyAmt(cnBalance[i].amount)
				}
			}
		}
	}

	const getbuytokencontent = async () => {
		let getData = await axios.get(
			CONST.BACKEND_URL + `/ico/getallroundcontent`
		)

		if (getData.data.status == 200) {
			setGetContent(getData.data.data)
		}
	}

	const fetchData = async () => {
		getdata = await axios.post(
			CONST.BACKEND_URL + '/users/getallactivecurrency',
			{ wallet: 'metamask' }
		)

		setCurrencyDetails(getdata.data.data)
	}
	const getUsdtData1 = async () => {
		let dolAmt = parseFloat(minDolAmt)
		let getresult = await axios.get(CONST.BACKEND_URL + '/ico/getAmount')

		if (getresult.data.status == 200) {
			getdata = await axios.get(CONST.BACKEND_URL + '/ico/coin_convert')

			setUsdtPrice(getdata.data.data.USD)
		}
	}

	const getUsdtData = async data1 => {
		let UsdtPriceforCurrency
		let usdtPrice
		let dolAmt = parseFloat(minDolAmt)
		let getresult = await axios.get(CONST.BACKEND_URL + '/ico/getAmount')

		if (getresult.data.status == 200) {
			getdata = await axios.get(CONST.BACKEND_URL + '/ico/coin_convert')

			if (data1.symbol == 'USDT') {
				usdtPrice = getdata.data.data.USD
				setUsdtPrice(getdata.data.data.USD)
			} else {
				UsdtPriceforCurrency = Number(getresult.data.USDT_amount)
			}
			getData(
				data1.symbol,
				data1.usd_value,
				UsdtPriceforCurrency,
				usdtPrice
			)
		}
	}

	const getData = async (data, UsdValue, UsdtPriceforCurrency, usdtPrice) => {
		let getCurAmt

		let maxamount = siteInform.max_amount

		if (data == 'BTYC') {
			let amt = Number(UsdtPriceforCurrency) / UsdValue
			setUsdtPrice(amt)
			setBigshotAmt(amt)
		} else if (data == 'BUSD') {
			let amt = Number(usdtPrice) / allCurAmount.BUSD
			setBigshotAmt(amt)
		} else if (data == 'USDT') {
			let amt = Number(usdtPrice) / UsdValue
			setBigshotAmt(amt)
		} else if (data == 'BNB') {
			let amt = Number(UsdtPriceforCurrency) / UsdValue
			setBigshotAmt(amt)
			setUsdtPrice(amt)
		} else if (data == 'ETH') {
			let amt = Number(UsdtPriceforCurrency) / UsdValue
			setBigshotAmt(amt)
			setUsdtPrice(amt)
		}
		setCurAmount('')
		setCurrencyAmt(0)
	}

	const getCurrency = data => {
		setSelectedCurrencyValueInUsd(data.usd_value)
		getUsdtData1()
		getBalance(data)
		getUsdtData(data)
		setCurrency(data.currency_name)
		setCUrSymbol(data.symbol)
		setcurImg(data.image)
		setCode(uuid())

		const curValueInBsbt = Object.entries(bsbtValue).find(t => {
			return t[0] === data.symbol
		})[1]
		setCurInBsbt(curValueInBsbt)
	}

	const getAmount = async value => {
		if (value < 0) {
			toast.error('Enter Valid Amount')
			setCurAmount(value)
		} else if (value == '0') {
			setCurrencyAmt(0)
			setCurAmount(value)
		} else if (value.length > 8) {
			setInputLength(false)
			toast.error('please Enter less than 8 digits')
		} else {
			setCurAmount(value)
			setInputLength(true)

			setMinBigshotBuyAmt(bigshotAmt * value)
			let maxamount = siteInform.max_amount

			setCurrencyAmt(curInBsbt * +value)
		}
	}

	let tws

	tws = setInterval(function () {
		if (typeof window.tronWeb != 'undefined') {
			clearInterval(tws)
			tronWebFn()
		}
	}, 100)
	async function tronWebFn() {}
	const getChainId = () => {
		if (window.ethereum) {
			window.ethereum.on('networkChanged', function (networkId) {
				setChainId(networkId)
			})
		}
	}

	const getWalletAddress = e => {
		if (e.target.value == '') {
			document.getElementById('error2').innerHTML =
				'Please Enter User Address'
		} else if (!/0x[a-fA-F0-9]{40}/.test(e.target.value)) {
			document.getElementById('error2').innerHTML =
				'Please Enter Valid User Address'
		} else {
			document.getElementById('error2').innerHTML = ''

			setWalletAddress(e.target.value)
		}
	}
	const connectWallet = () => {
		connectWalletModalService.sendMessage('open')
	}

	const buynow = async amount => {
		const res = await checkLimit()

		if (res.data.status === 200) {
			let amount = currencyAmt * 1000000000000000000
			setLoading(true)
			if (currencyAmt <= 0) {
				return
			}
			buyBigShotToken(
				currency,
				currencyAmt,
				curAmount,
				walletAddress,
				res => {
					setLoading(false)
					if (res) {
						navigate('/history')
						return
					}
				}
			)
		} else if (res.data.status === 401) {
			toast.error('Something went wrong. Try again later')
		} else {
			toast.error(
				`Daily limit reached. Remaining amount: ${res.data.remainAmount}`
			)
		}
	}

	var isMobile = window.matchMedia(
		'only screen and (max-width: 480px)'
	).matches

	const checkLimit = async () => {
		return axios.post(CONST.BACKEND_URL + '/ico/checKBuyToken', {
			from_address: sessionStorage.getItem('bigShotAccount'),
			currency: curSymbol,
			request_amount: curAmount
		})
	}

	return (
		<Fragment>
			<div className={loading ? 'spinner' : 'd-none'}>
				<FadeLoader color={color} size={50} />
			</div>
			<div className='cntld InnrMain'>
				<div className='btpg'>
					<div className='container'>
						<div className='row justify-content-center align-items-start'>
							<div className='col-lg-8 my-2'>
								<div className='btdv p-4'>
									<h3
										className='text-center mb-4'
										style={{ color: 'white' }}
									>
										Buy Big Shot Tokens
									</h3>
									{sessionStorage.getItem(
										'bigShotAccount'
									) ? (
										<Link to='/history'>
											<img
												src={refreshImg}
												className='refimg'
											/>
										</Link>
									) : null}

									<div className='tknlst mb-3 cus-select'>
										<label>Select Currency</label>

										<UncontrolledDropdown>
											<DropdownToggle caret>
												<div className='d-flex justify-content-between align-items-center'>
													<div className='ldv'>
														{curImg ? (
															<img
																src={curImg}
																className='icon-size'
															/>
														) : (
															<></>
														)}
														<span className='ms-2'>
															{currency}
														</span>
													</div>
													<img src={downarrowImg} />
												</div>
											</DropdownToggle>

											<DropdownMenu>
												{currencyDetails &&
													currencyDetails.map(
														(user, index) => (
															<DropdownItem
																key={index}
																onClick={() =>
																	getCurrency(
																		user
																	)
																}
															>
																<img
																	className='icon-size'
																	src={
																		user.image
																	}
																/>
																<span className='ms-2'>
																	{
																		user.currency_name
																	}
																</span>
															</DropdownItem>
														)
													)}
											</DropdownMenu>
										</UncontrolledDropdown>
										<div className='mktplc mt-2 d-block text-end'>
											{currency &&
											currency == 'Select Currency' ? (
												<></>
											) : (
												<span
													style={{ color: 'white' }}
												>
													{' '}
												</span>
											)}
											{curInBsbt === '' ? (
												<></>
											) : (
												<label
													className='val d-block text-end'
													style={{ color: 'white' }}
												>
													1 BSBT = {curInBsbt}{' '}
													{curSymbol}{' '}
												</label>
											)}
										</div>
									</div>
									<div className='tknlst mb-2'>
										<label>Enter Big Shot Token</label>
										<InputGroup>
											<InputGroupText>
												<img
													src={btcImg}
													className='icon-size'
												/>
											</InputGroupText>
											{
												currency &&
												currency ==
													'Select Currency' ? (
													<Input
														placeholder='Enter the Big Shot Amount'
														disabled
													/>
												) : (
													<NumericFormat
														placeholder='Enter the Bigshot Amount'
														allowNegative={false}
														value={curAmount}
														customInput={Input}
														isAllowed={values => {
															const {
																value
															} = values
															return (
																value.length < 8
															)
														}}
														onValueChange={data => {
															getAmount(
																data.value
															)
														}}
													/>
												)
											}
										</InputGroup>
										<div className='mt-2'>
											<span
												id='error'
												style={{ color: 'red' }}
											></span>

											<div className='minmax-value'>
												<span
													className='val'
													style={{ color: 'white' }}
												>
													Minimum Buy: {limit.minimum}{' '}
													Big Shot
												</span>
												<span
													className='val'
													style={{ color: 'white' }}
												>
													Daily limit:{' '}
													{limit.dailyLimit} Big Shot
												</span>
											</div>
										</div>
									</div>
									<div className='usdtvl mb-4'>
										{!curAmount ? (
											<>
												<span name='amount'>0</span>
											</>
										) : curSymbol === 'ETH' ? (
											<>
												<span
													name='amount'
													value={
														curInBsbt * +curAmount
													}
													style={{ color: 'white' }}
												>
													{curInBsbt * +curAmount}
												</span>{' '}
											</>
										) : (
											<span
												name='amount'
												value={curInBsbt * +curAmount}
												style={{ color: 'white' }}
											>
												{curInBsbt * +curAmount}
											</span>
										)}
										<div className='rdiv '>
											{currency == 'Select Currency' ? (
												<></>
											) : (
												<img
													className='icon-size'
													src={curImg}
												/>
											)}
											<span className='val ms-2'>
												{curSymbol}
											</span>
										</div>
									</div>
									{sessionStorage.getItem('walletType') ==
									'tron' ? (
										<>
											<div className='tknlst mb-2'>
												<Input
													type='text'
													className='user-address'
													placeholder='Enter the user Address'
													onChange={getWalletAddress}
												/>
											</div>
											<span
												id='error2'
												style={{ color: 'red' }}
											></span>
										</>
									) : (
										<></>
									)}

									<div className='d-flex justify-content-center'>
										{!sessionStorage.getItem(
											'bigShotAccount'
										) ? (
											<button
												className='btn btn-gold'
												onClick={connectWallet}
											>
												Connect Wallet
											</button>
										) : (currency &&
												currency ==
													'Select Currency') ||
										  curAmount === undefined ||
										  !curAmount ||
										  curAmount <= 0 ||
										  inoutLength === false ||
										  parseFloat(minBuyBigshotAmt) >
												parseFloat(curAmount) ? (
											<button
												className='btn btn-gold px-5'
												disabled
											>
												Buy Now
											</button>
										) : sessionStorage.getItem(
												'walletType'
										  ) == 'tron' ? (
											(currency &&
												currency ==
													'Select Currency') ||
											walletAddress == '' ||
											!walletAddress ||
											!/0x[a-fA-F0-9]{40}/.test(
												walletAddress
											) ||
											curAmount === undefined ||
											!curAmount ||
											curAmount <= 0 ||
											inoutLength === false ||
											parseFloat(minBuyBigshotAmt) >
												parseFloat(curAmount) ||
											parseFloat(maxBuyBigshotAmt) <
												parseFloat(curAmount) ? (
												<button
													className='btn btn-gold px-5'
													disabled
												>
													Buy Now
												</button>
											) : (
												<button
													className='btn whitepaper-btn px-5'
													style={{
														borderRadius: '50px'
													}}
													onClick={() =>
														buynow(
															curInBsbt *
																+curAmount
														)
													}
												>
													Buy Now
												</button>
											)
										) : (currency &&
												currency ==
													'Select Currency') ||
										  curAmount === undefined ||
										  !curAmount ||
										  curAmount <= 0 ||
										  inoutLength === false ||
										  parseFloat(minBuyBigshotAmt) >
												parseFloat(curAmount) ||
										  parseFloat(maxBuyBigshotAmt) <
												parseFloat(curAmount) ||
										  parseFloat(minBuyBigshotAmt) >
												parseFloat(
													siteInform.max_amount
												) ? (
											<button
												className='btn btn-gold px-5'
												disabled
											>
												Buy Now
											</button>
										) : (
											<button
												className='btn whitepaper-btn px-5'
												style={{ borderRadius: '50px' }}
												onClick={() =>
													buynow(
														curInBsbt * +curAmount
													)
												}
											>
												Buy Now
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
