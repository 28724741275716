const ContractDetails = {
    token_contract: "0x1c9D3819dbBc4D6bE73B1BeDcaB2EB9501256FEc",
    Eth_contract : '0x910A08F285214b9E1406e71eF57c60dBeb17Ac62',
    Bsc_contract : '0xc7E8443D46e2F4D3544Aa6AAD46f1445d16a2bD8',
    Tron_contract : 'TJoSdE9FW1xE79EMkDQwkrj69HWg7iBe5f',
    // Release_contract : '0xb222aB28c402Bd01624087C47E7ec57EB3393485'
    // Ico_contract: "0x9f1b801ec38a598BBa713655e9cA6b388E2Ca570",
    Ico_contract: "0x6C797ea3CA2558A660c7082eFA84D8658dA839b6",
    // Vesting_contract: '0x6CD1700fF5798194Be345c3e94d597372FdAc1eb',
    Vesting_contract: '0xE02bBe98C4429c19Cc53892C8D68d0294709B1f5',
    Gusd_contract:'0x64EfE69bc1B72d6764e9Fc15557a17a26820cA31'
};
export default ContractDetails;