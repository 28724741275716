import { put, takeEvery, all,call } from 'redux-saga/effects';
import axios from 'axios';

const URL = 'https://jsonplaceholder.typicode.com/posts';
function* FetchAllUsers(){
	yield put({type:'SET_LOADING'});
	const allUsers = yield axios.get(URL);
	yield put({type: 'GET_ALL_USERS_SUCCESS', responseData : allUsers})

}
function* actionWatcher(){
	yield takeEvery('GET_ALL_USERS_REQUEST',FetchAllUsers);

}
export default function* rootSaga(){
	yield all ([actionWatcher()]);
}