import React, { Component, Fragment, useState } from "react";
import '../underconstruction/under.scss'
import logo from '../../assets/images/logo.svg';
import construction from '../../assets/images/construction.png';
import { Link } from "react-router-dom";


const UnderConstruction = (props) => {	
  
    return (
      <Fragment>
      <div class="cms-maincnt">
        <div class="cms-cnt">
            <div>
                <div><a href=""><img src={logo} alt="" /></a></div> 
                <div class="mt-5"><img src={construction} alt="" class="img-fluid" /></div>
                <div class="mt-5"><h5>Website Under Construction</h5></div> 
                
            </div>
        </div>
      </div>
      </Fragment>
    );
    
}

export default UnderConstruction;