import React, { useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'

const Idle = () =>{

   

    useEffect(()=>{
      timecheck()
    },[])

    
  // DOM Events
   var getLatestTime = setInterval(function(){
    localStorage.setItem('newtime',new Date().getTime())
    
    timecheck()
   },1000)
  
  
      document.addEventListener("mousemove", () =>{ 
        localStorage.setItem('lastActvity', new Date().getTime())
        resetTimer();
        
      });
      document.addEventListener("click", () =>{ 
        localStorage.setItem('lastActvity', new Date().getTime())
        resetTimer();
       
      });

      document.addEventListener('keypress', (event) => {
        localStorage.setItem('lastActvity', new Date().getTime())
        resetTimer();
      }, false);
      // document.onmousemove = resetTimer;
      // document.onkeypress = resetTimer;
      function resetTimer() {
       var time =  localStorage.getItem('lastActvity')
        localStorage.setItem('expiretime',Number(time)+ 1*1000*60*60)
       
        timecheck()
      }

      var count=0;
      const timecheck = () =>{
        
        var newTime;
        let expiretime = localStorage.getItem('expiretime')
        
        if(Number(localStorage.getItem('newtime'))>Number(expiretime)){
          count =count+ 1;
          
          if(count==1){
            
            if(localStorage.getItem('bigshotloginId')){
            sessionStorage.removeItem('bigshotloginId');
            sessionStorage.removeItem('bigshotemail')
            localStorage.removeItem('bigshotemail')
            sessionStorage.removeItem('referralcode')
            localStorage.removeItem('bigshotloginId');
		        window.location.reload()
            }
            else{

            }

            
          }
          else{

          }
          
          
          
        }
        else if(Number(localStorage.getItem('newtime'))<Number(expiretime)){
         
        }

      }

      const logout = () =>{
       
        clearInterval()
      }
 
    return(
        <></>
    )
}
export default Idle