import { useState, useEffect } from 'react'
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import FadeLoader from 'react-spinners/FadeLoader'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'

import { CONST } from '../../config/index'
import { scrollToTop } from '../../utils'
import connectWalletModalService from '../../common/connectWalletModalService'

import './styles.scss'

import cban from '../../assets/images/contact-banner.png'
import downarrowImg from '../../assets/images/downarrow.svg'

const EMAILID_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const schema = yup.object().shape({
	name: yup
		.string()
		.required('Enter a name')
		.min(3, 'Name must have atleast 3 characters')
		.max(18, 'Name must have atmost 18 characters'),
	email: yup
		.string()
		.required('Enter an email')
		.matches(EMAILID_REGEX, 'Enter a valid email'),
	category: yup.string().required('Enter a category'),
	subject: yup.string().required('Enter a subject'),
	message: yup.string().required('Enter a message')
})

const ContactUs = () => {
	const [categories, setCategories] = useState([])
	const [loading, setLoading] = useState(false)

	const methods = useForm({
		mode: 'all',
		defaultValues: {
			name: '',
			email: '',
			category: '',
			subject: '',
			message: ''
		},
		resolver: yupResolver(schema)
	})
	const { reset, control, formState, handleSubmit } = methods
	const { errors } = formState

	useEffect(() => {
		const subscription = connectWalletModalService
			.getMessage()
			.subscribe(({ component }) => {
				if (component === 'scroll-to-top') {
					scrollToTop()
				}
			})

		const getCategories = async () => {
			const res = await axios.get(
				CONST.BACKEND_URL + '/users/viewcategory'
			)
			setCategories(res.data.category)
		}

		getCategories()
		return () => {
			subscription.unsubscribe()
		}
	}, [])

	const submitContactUsForm = async data => {
		setLoading(true)
		const res = await axios.post(
			CONST.BACKEND_URL + '/users/contact_us',
			data
		)

		if (res.data.status === 200) {
			toast.success(res.data.message)
			setLoading(false)
			reset()
		}
	}

	return (
		<>
			<div className={loading ? 'spinner' : 'd-none'}>
				<FadeLoader color='#5AC41E' size={50} />
			</div>
			<section className='contact-bg'>
				<div class='pgCnt'>
					<main class='contact-wrapper mt-4'>
						<div class='contact-banner'>
							<img src={cban} alt='' />
						</div>
						<div class='container'>
							<div class='row'>
								<div class='col-lg-5'>
									<form
										onSubmit={handleSubmit(
											submitContactUsForm
										)}
									>
										<div class='heading'>
											<h1>
												Contact
												<span>Us</span>
											</h1>
										</div>

										<div class='form-group'>
											<Controller
												name='name'
												control={control}
												render={({ field }) => (
													<>
														<label
															for='name'
															className='mb-2'
														>
															Name
														</label>
														<input
															{...field}
															id='name'
															name='name'
															type='text'
															class='form-control'
															placeholder=' Enter name'
															autoFocus
															autoComplete='off'
														/>
														{errors?.name
															?.message && (
															<p className='mt-1 error-msg'>
																{
																	errors?.name
																		?.message
																}
															</p>
														)}
													</>
												)}
											/>
										</div>

										<div class='form-group'>
											<Controller
												name='email'
												control={control}
												render={({ field }) => (
													<>
														<label
															for='email'
															className='mb-2'
														>
															Email-id
														</label>
														<input
															{...field}
															id='email'
															name='email'
															type='text'
															class='form-control'
															placeholder='Enter email-id'
															autoComplete='off'
														/>
														{errors?.email
															?.message && (
															<p className='mt-1 error-msg'>
																{
																	errors
																		?.email
																		?.message
																}
															</p>
														)}
													</>
												)}
											/>
										</div>

										<div class='tknlst mb-3 cus-select mt-md'>
											<Controller
												name='category'
												control={control}
												render={({ field }) => {
													return (
														<>
															<label>
																Category
															</label>

															<UncontrolledDropdown
																onBlur={
																	field.onBlur
																}
															>
																<DropdownToggle
																	caret
																>
																	<div className='d-flex justify-content-between align-items-center'>
																		<div className='ldv'>
																			<span className='ms-2'>
																				{field.value ===
																				''
																					? 'Select category'
																					: field.value}
																			</span>
																		</div>
																		<img
																			src={
																				downarrowImg
																			}
																		/>
																	</div>
																</DropdownToggle>

																<DropdownMenu>
																	{categories &&
																		categories.map(
																			(
																				category,
																				index
																			) => (
																				<DropdownItem
																					key={
																						index
																					}
																					onClick={() =>
																						field.onChange(
																							category
																						)
																					}
																				>
																					<span className='ms-2'>
																						{
																							category
																						}
																					</span>
																				</DropdownItem>
																			)
																		)}
																</DropdownMenu>
															</UncontrolledDropdown>
															{errors?.category
																?.message && (
																<p className='mt-1 error-msg'>
																	{
																		errors
																			?.category
																			?.message
																	}
																</p>
															)}
														</>
													)
												}}
											/>
										</div>

										<div class='form-group'>
											<Controller
												name='subject'
												control={control}
												render={({ field }) => (
													<>
														<label
															for='subject'
															className='mb-2'
														>
															Subject
														</label>
														<input
															{...field}
															id='subject'
															name='subject'
															type='text'
															class='form-control'
															placeholder='Enter subject'
															autoComplete='off'
														/>
														{errors?.subject
															?.message && (
															<p className='mt-1 error-msg'>
																{
																	errors
																		?.subject
																		?.message
																}
															</p>
														)}
													</>
												)}
											/>
										</div>

										<div class='form-group'>
											<Controller
												name='message'
												control={control}
												render={({ field }) => (
													<>
														<label
															for='message'
															className='mb-2'
														>
															Message
														</label>
														<textarea
															{...field}
															id='message'
															name='message'
															class='form-control'
															rows='8'
															placeholder='Enter message'
															autoComplete='off'
														></textarea>
														{errors?.message
															?.message && (
															<p className='mt-1 error-msg'>
																{
																	errors
																		?.message
																		?.message
																}
															</p>
														)}
													</>
												)}
											/>
										</div>

										<div class=''>
											<button class='btn mt-4 submit-btn whitepaper-btn'>
												Submit
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</main>
				</div>
			</section>
		</>
	)
}

export default ContactUs
