import React, { useState, useEffect } from 'react'
import Pagination from '@mui/material/Pagination'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FadeLoader } from 'react-spinners'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'

import { CONST } from '../../config'
import { copyText, scrollToTop, formatDateToDefaultLocale } from '../../utils'
import connectWalletModalService from '../../common/connectWalletModalService'
import { ChangeNetwork } from '../../common/Services'
import contractDetails from '../../common/contracts'
import bigShotClaimAbi from '../../common/abi/bigShotAiClaim.json'

import copy from '../../assets/images/copy.png'
import useDebounce from '../../hooks/useDebounce'

const REF_URL = 'https://www.bigshot.games/referral/'

const Referral = () => {
	toast.configure()
	let web3

	const [id, setId] = useState('')
	const [referralHistory, setReferralHistory] = useState([])
	const [showClaimCol, setShowClaimCol] = useState(false)
	const [loading, setLoading] = useState(false)
	const [sortBy, setSortBy] = useState(-1)
	const [entries, setEntries] = useState(5)
	const [searchText, setSearchText] = useState('')
	const [entriesCount, setEntriesCount] = useState(0)
	const [page, setPage] = useState(1)
	const [referralContent, setReferralContent] = useState()

	const debouncedSearchText = useDebounce(searchText)

	useEffect(() => {
		scrollToTop()
		getReferralData()
		getReferralContent()
	}, [])

	useEffect(() => {
		getReferralHistory()
	}, [sortBy, entries, debouncedSearchText, page])

	const isConnected =
		sessionStorage.getItem('bigShotAccount') === null ? false : true

	const copyClipboard = () => {
		copyText(id, () => {
			toast.success('Link copied')
		})
	}

	const getReferralData = async () => {
		let data = {
			address: sessionStorage.getItem('bigShotAccount')
		}
		const initData = await axios.post(
			CONST.BACKEND_URL + '/ico/getRefLink',
			data
		)
		if (initData?.data?.status == 200) {
			setId(REF_URL + initData?.data?.refLink)
		}
	}

	async function getReferralContent() {
		const { data } = await axios.get(
			CONST.BACKEND_URL + '/users/getreferralcontent'
		)

		if (data.status) {
			setReferralContent(data.data)
		}
	}

	const getReferralHistory = async () => {
		let data = {
			address: sessionStorage.getItem('bigShotAccount'),
			page: page - 1,
			sort: sortBy,
			pageSize: entries,
			search: debouncedSearchText
		}

		const res = await axios.post(CONST.BACKEND_URL + '/ico/refData', data)
		setShowClaimCol(res.data.userRefStatus)
		setEntriesCount(res.data.count)
		if (res.data.count === 0) {
			setReferralHistory([])
			return
		}
		if (res.data.status === 200) {
			setReferralHistory(res.data.claimData)
			return
		}
	}

	const claim = async (data) => {
		setLoading(true)
		const walletType = localStorage.getItem('wallet')

		if (walletType === 'Metamask' || walletType === 'Trust') {
			web3 = new Web3(window.ethereum)
		} else {
			const provider = new WalletConnectProvider({
				rpc: {
					56: 'https://bsc-dataseed1.ninicoin.io',
					1: 'https://mainnet.infura.io/v3/'
				}
			})
			web3 = new Web3(provider)
		}

		try {
			await ChangeNetwork.Bnbnetwork()
			const claimContract = new web3.eth.Contract(
				bigShotClaimAbi,
				contractDetails.claim
			)
			let amount = data.referal_amount * 1e18
			const deadline = (new Date().getTime() + 10000).toString()
			const hash = await claimContract.methods
				.prepareClaimHash(
					sessionStorage.getItem('bigShotAccount'),
					amount.toString(),
					deadline
				)
				.call()
			const res = await axios.post(CONST.BACKEND_URL + '/ico/claimVrs', {
				claimHash: hash
			})
			const tx = await claimContract.methods
				.claimToken(
					amount.toString(),
					deadline,
					res.data.v,
					res.data.r,
					res.data.s
				)
				.send({ from: sessionStorage.getItem('bigShotAccount') })

			axios
				.post(CONST.BACKEND_URL + '/ico/refDataUpdate', {
					address: sessionStorage.getItem('bigShotAccount'),
					refId: data._id,
					txnid: tx.transactionHash
				})
				.then((res) => {
					if (res.status === 200) {
						toast.success('Claim successful')
						getReferralHistory()
						setLoading(false)
					}
				})
		} catch (err) {
			console.log(err)
			toast.error('Transaction rejected')
			setLoading(false)
		}
	}

	const totalPages =
		entriesCount % entries !== 0
			? Math.floor(entriesCount / entries) + 1
			: entriesCount / entries

	return (
		<>
			<div className={loading ? 'spinner' : 'd-none'}>
				<div>
					<FadeLoader color='#5AC41E' size={50} />
				</div>
			</div>
			<section className='contact-bg'>
				<div class='pgCnt'>
					<main class='referal-wrapper'>
						<div class='referal-header'>
							<div class='container'>
								<div class='heading'>
									<h2
										dangerouslySetInnerHTML={{
											__html: referralContent?.title
										}}
									/>
									<p
										dangerouslySetInnerHTML={{
											__html: referralContent?.content
										}}
									></p>
								</div>
								{isConnected ? (
									<div class='form-group'>
										<label for=''>Referral Link</label>
										<div class='input-group'>
											<input
												type='text'
												placeholder={id}
												value={id}
												readonly
												class='form-control'
											/>
											<div class='input-group-append'>
												<button class='input-group-text'>
													<span
														className='cpy_btn p-2 copybtn'
														id='emailHelp'
													>
														<img
															src={copy}
															onClick={() =>
																copyClipboard()
															}
														/>
													</span>
												</button>
											</div>
										</div>
									</div>
								) : (
									<div class='form-group'>
										<Link
											class='btn btn-15538 whitepaper-btn'
											onClick={() => {
												connectWalletModalService.sendMessage(
													'open'
												)
											}}
										>
											Connect Wallet
										</Link>
									</div>
								)}
							</div>
						</div>

						<div class='container'>
							<div class='referal-body'>
								<div class='heading'>
									<h3>
										Referral <span> History</span>
									</h3>
								</div>
								<div class='referal-table-wrapper'>
									<div class='referal-number'>
										<p>Showing</p>
										<div class='sort'>
											<select
												class='custom-select w-60'
												id='inputGroupSelect01'
												onChange={(e) => {
													setEntries(e.target.value)
													setPage(1)
												}}
											>
												<option selected value='5'>
													5
												</option>
												<option value='10'>10</option>
												<option value='20'>20</option>
											</select>
										</div>
										<p>Entries</p>
									</div>
									<div class='sort-wrapper'>
										<div class='sort'>
											<p>Sort by</p>
											<select
												class='custom-select'
												id='inputGroupSelect01'
												onChange={(e) => {
													setSortBy(
														Number(e.target.value)
													)
												}}
											>
												<option selected value='-1'>
													Newest
												</option>
												<option value='1'>
													Oldest
												</option>
											</select>
										</div>
										<div class='search-wrapper'>
											<div class='form-group'>
												<div class='input-group'>
													<input
														type='text'
														placeholder='Search address'
														class='form-control'
														onChange={(e) =>
															setSearchText(
																e.target.value
															)
														}
													/>
													<div class='input-group-append'>
														<button class='input-group-text'>
															<svg
																width='18'
																height='19'
																viewBox='0 0 18 19'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M7.16667 13.8333C10.7105 13.8333 13.5833 10.9605 13.5833 7.41667C13.5833 3.87284 10.7105 1 7.16667 1C3.62284 1 0.75 3.87284 0.75 7.41667C0.75 10.9605 3.62284 13.8333 7.16667 13.8333Z'
																	stroke='white'
																	stroke-linecap='round'
																	stroke-linejoin='round'
																/>
																<path
																	d='M17.5 18.5L12 13'
																	stroke='white'
																	stroke-linecap='round'
																	stroke-linejoin='round'
																/>
															</svg>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class='bigshot-table '>
									<div class='table-bg table-responsive'>
										<Table aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell align='left'>
														Date & Time
													</TableCell>
													<TableCell align='left'>
														Referee Address
													</TableCell>
													<TableCell align='left'>
														Currency Purchased
													</TableCell>
													<TableCell align='left'>
														Reward Amount (BSBT)
													</TableCell>
													{showClaimCol && (
														<TableCell align='center'>
															Claim
														</TableCell>
													)}
												</TableRow>
											</TableHead>
											<TableBody>
												{referralHistory.length !==
												0 ? (
													referralHistory.map(
														(row) => (
															<TableRow
																key={row._id}
																sx={{
																	'&:last-child td, &:last-child th':
																		{
																			border: 0
																		}
																}}
															>
																<TableCell align='left'>
																	{formatDateToDefaultLocale(
																		row.created
																	)}
																</TableCell>
																<TableCell align='left'>
																	{
																		row.referre_address
																	}
																</TableCell>
																<TableCell align='left'>
																	{
																		row.currency
																	}
																</TableCell>
																<TableCell align='left'>
																	{
																		row.referal_amount
																	}
																</TableCell>
																{showClaimCol &&
																	(row.isClaim ? (
																		'Claimed'
																	) : (
																		<TableCell align='center'>
																			<button
																				className='btn btn-gold whitepaper-btn'
																				onClick={() => {
																					claim(
																						row
																					)
																				}}
																			>
																				Claim
																			</button>
																		</TableCell>
																	))}
															</TableRow>
														)
													)
												) : (
													<TableRow className='cell'>
														<TableCell
															colSpan={4}
															align='center'
															sx={{
																borderBottom:
																	'none'
															}}
														>
															<span>
																No Data Found
															</span>
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</div>
									<div class='table-footer'>
										<div class='entries'>
											{isConnected && (
												<p>
													Showing{' '}
													{entries > entriesCount
														? entriesCount
														: entries}{' '}
													of {entriesCount} results
												</p>
											)}
										</div>
										<div class='pagination'>
											<Pagination
												count={totalPages}
												defaultPage={1}
												page={page}
												onChange={(e, pageNumber) => {
													setPage(pageNumber)
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</section>
		</>
	)
}

export default Referral
