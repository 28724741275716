import "../Pagenotfound/scss/pagenotfound.scss";
import React, { Fragment, useState  } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupText, Input } from "reactstrap";
import refreshImg from "../../assets/images/refresh.svg";
import pagenotfoundImg from "../../assets/images/404.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Home = (props) => {
    return (
      <Fragment>
        <Header />
        <div className="cntld">
            <div className="btpg pgntfd">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-lg-12">
                        <h3>Something’s Missing </h3>
                        <img src={pagenotfoundImg} className="img-fluid d-block mx-auto"/>
                        <p className="mb-4">This page doesn’t exist or was removed !<br></br>We suggest you back to home</p>
                        <button className="btn btn-gr">Back to Home</button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </Fragment>
    );
   
}

export default Home;