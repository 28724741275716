import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Popover,
	PopoverBody,
	PopoverHeader
} from 'reactstrap'
import classnames from 'classnames'
import { Link, useParams, useLocation } from 'react-router-dom'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import bnrWvs from '../../assets/images/bnrWvs.svg'
import bnrIg from '../../assets/images/bnrIg.png'
import bnrOl from '../../assets/images/bnrOl.png'
import y from '../../assets/images/y.svg'
import p from '../../assets/images/p.svg'
import g from '../../assets/images/g.svg'
import b from '../../assets/images/b.svg'
import graph from '../../assets/images/graph.png'
import arrleft from '../../assets/images/arrow-left.svg'
import arrright from '../../assets/images/arrow-right.svg'
import orCnOl from '../../assets/images/orCnOl.png'
import userexp from '../../assets/images/user-exp.svg'
import quality from '../../assets/images/quality.svg'
import trust from '../../assets/images/trust.svg'
import lowfees from '../../assets/images/low-fees.svg'
import rdMpOl from '../../assets/images/rdMpOl.png'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import axios from 'axios'
import { CONST } from '../../config'
import Chart from 'react-apexcharts'
import connectWalletModalService from '../../common/connectWalletModalService'

import './styles.scss'

import mrlk from '../../assets/images/moreLikeOl.svg'
import strrbn from '../../assets/images/star-ribbon.png'
import mny3d from '../../assets/images/money-3d.png'
import mnybg from '../../assets/images/money-bag.png'
import mny3d2 from '../../assets/images/3d-money-icon.png'

import tempImg from '../../assets/images/tkn-chart.png'
import chartempImg from '../../assets/images/chart-piece.png'

import ecu from '../../assets/images/economics.png'
import cnbc from '../../assets/images/cnbc.png'
import dlyhnt from '../../assets/images/dailyhunt.png'
import yho from '../../assets/images/yahoo.png'
import logobig from '../../assets/images/logobig.png'

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { scrollToTop } from '../../utils'

const Home = ({
	homeRef,
	whitePaperRef,
	roadMaprRef,
	handleWhitePaperBtn,
	sectionToScroll,
	setRefId
}) => {
	var settings = {
		dots: false,
		infinite: true,
		arrows: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	var settings1 = {
		dots: false,
		infinite: true,
		arrows: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	const params = useParams()

	useEffect(() => {
		if (params.id && sessionStorage.getItem('bigShotAccount') === null) {
			connectWalletModalService.sendMessage('open')
			sessionStorage.setItem('refId', params.id)
			setRefId(params.id)
		}
	}, [params])

	const [modal1, setModal1] = useState(
		sessionStorage.getItem('canShowModal') === null
			? true
			: sessionStorage.getItem('canShowModal') === 'false'
			? false
			: true
	)
	const toggleLaunch = () => {
		sessionStorage.setItem('canShowModal', 'false')
		setModal1(!modal1)
	}

	const [activeTab, setActiveTab] = useState('1')
	const toggletab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab)
	}
	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)
	const [popoverOpen, setpopoverOpen] = useState(-1)
	const [setting, setSetting] = useState({})
	const onHover = (i) => {
		setpopoverOpen(i)
	}
	const onHoverLeave = () => {
		setpopoverOpen(-1)
	}

	const [matches, setMatches] = useState(
		window.matchMedia('(min-width: 768px)').matches
	)

	const [days, setDays] = useState('00')
	const [hours, setHours] = useState('00')
	const [minutes, setMinutes] = useState('00')
	const [seconds, setSeconds] = useState('00')

	const [x, setX] = useState(false)

	const [siteInfo, setSiteInfo] = useState({})

	const [cms, setCms] = useState([])
	const [supplyData, setSupplyData] = useState({
		getSupply: 0,
		totalSupply: 0
	})
	const [roadMap, setRoadMap] = useState([])
	const [chartinfo, setChartInfo] = useState({
		series: [],
		labels: [],
		colors: [
			'#de15d6',
			'#3d15de',
			'#b5a3ff',
			'#d92d6b',
			'#2dd9ba',
			'#c1c502',
			'#7339ef',
			'#de15d6',
			'#de15d6',
			'#ffd056'
		],
		stroke: {
			show: false
		},
		legend: {
			show: false
		}
	})

	const [coinStructure, setCoinStructure] = useState({
		title_1: '',
		title_2: '',
		title_3: '',
		title_4: ''
	})
	const [coinInfo, setCoinInfo] = useState({
		title_1: '',
		title_2: '',
		title_3: '',
		title_4: '',
		content_1: '',
		content_2: '',
		content_3: '',
		content_4: ''
	})
	const [tokenDistributionInfo, setTokenDistributionInfo] = useState()
	const [faq, setFaq] = useState()
	const [moreLikelyToSignupContents, setMoreLikelyToSignupContents] =
		useState()
	const [howToBuyContents, setHowToBuyContents] = useState()
	const [currentSupply, setCurrentSupply] = useState(0)
	const [featuredAcrossTheGlobeContents, setFeaturedAcrossTheGlobeContents] =
		useState()

	const fetchInitData = async () => {
		let initData = await axios.get(CONST.BACKEND_URL + '/users/homePage')
		if (initData.data.status) {
			var homeDatas = initData.data.data
			setSiteInfo(homeDatas?.getSiteInform[0])
			setCms(homeDatas?.getCms)

			setSupplyData({
				getSupply: homeDatas?.getSupply,
				totalSupply: homeDatas?.totalSupply
			})
			setRoadMap(homeDatas?.getRoadmap)
			setCoinStructure(homeDatas?.getCoinStructure[0])
			setCoinInfo(homeDatas?.getCoinInfo[0])
			setChartInfo({
				...chartinfo,
				['series']: Object.values(homeDatas?.getTokenomics[1]),
				['labels']: Object.keys(homeDatas?.getTokenomics[1])
			})
			setTokenDistributionInfo(homeDatas?.getTokenomics[0])
			setFaq(homeDatas?.getFaq)
			setMoreLikelyToSignupContents(homeDatas?.getMoreCMS[0])
			setHowToBuyContents(homeDatas?.getHowToCMS[0])
			setFeaturedAcrossTheGlobeContents(homeDatas?.getNews)
			setCurrentSupply(homeDatas?.getTotalSubbly[0]?.amount)

			testFun(homeDatas?.getSiteInform[0]?.launchDate)
		} else {
		}
	}

	let interval = useRef()

	const testFun = (time) => {
		const countdownDate = new Date(Date.parse(time)).getTime()

		if (x) {
			clearInterval(x)
		}

		setX(
			setInterval(() => {
				const now = new Date().getTime()
				const difference = countdownDate - now

				const days = Math.floor(difference / (1000 * 60 * 60 * 24))
				const hours = Math.floor(
					(difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				)
				const minutes = Math.floor(
					(difference % (1000 * 60 * 60)) / (1000 * 60)
				)
				const seconds = Math.floor((difference % (1000 * 60)) / 1000)

				if (difference < 0) {
					clearInterval(interval.current)
				} else {
					setDays(days)
					setHours(hours)
					setMinutes(minutes)
					setSeconds(seconds)
				}
			}, 1000)
		)
	}

	useEffect(() => {
		fetchInitData()

		const subscription = connectWalletModalService
			.getMessage()
			.subscribe(({ component }) => {
				if (component === 'scroll-to-top') {
					scrollToTop()
				}
			})
		return () => {
			subscription.unsubscribe()
		}
	}, [])
	const { state } = useLocation()

	useEffect(() => {
		if (sectionToScroll === '') return

		if (sectionToScroll === 'whitepaper') {
			const whitepaperDiv = document.getElementById('whitepaper')
			whitepaperDiv.scrollIntoView({ behavior: 'smooth', block: 'end' })
			// const val = state.previousPath === '/referral' ? 700 : 0
			// window.scrollTo({
			// 	behavior: 'smooth',
			// 	top: whitepaperDiv.offsetTop + val
			// })
			return
		}

		if (sectionToScroll === 'roadmap') {
			const roadmapDiv = document.getElementById('roadmap')
			roadmapDiv.scrollIntoView({ behavior: 'smooth', block: 'start' })
			// const val = state.previousPath === '/referral' ? 700 : 0
			// window.scrollTo({
			// 	behavior: 'smooth',
			// 	top: roadMaprRef.current.offsetTop + val
			// })

			return
		}
	}, [sectionToScroll])

	const handleHowToBuyClick = (e) => {
		e.preventDefault()
		toggle()
	}
	return (
		<Fragment>
			<div class='pgCnt' ref={homeRef}>
				<div class='indBnr'>
					<div class='iBnrOl'>
						<img src={bnrOl} alt='bnrOl' />
					</div>
					<div class='container'>
						<div class='row iBnrR'>
							<div class='col-sm-12 col-md-12 col-lg-7 order-2 order-lg-1 iBnrC'>
								<div class='iBnrTx'>
									<h2
										dangerouslySetInnerHTML={{
											__html: cms[0]?.title
										}}
									/>
									<p
										dangerouslySetInnerHTML={{
											__html: cms[0]?.content_description
										}}
									/>

									<div class='iBnrLk'>
										<Link
											to='/buytoken'
											class='btn btn-14748 whitepaper-btn'
										>
											Buy Token
										</Link>

										<a
											href=''
											class='btn btn-14748 btn-grOl'
											onClick={handleHowToBuyClick}
										>
											How to buy
										</a>
									</div>
								</div>
							</div>
							<div class='col-sm-12 col-md-8 col-lg-5 order-1 order-lg-2 iBnrC'>
								<div class='bnrIgOl'>
									<img src={bnrWvs} alt='bnrWvs' />
								</div>
								<div class='bnrIg'>
									<img src={bnrIg} alt='bnrIg' />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class='prSl'>
					<div class='container'>
						<div class='prSlBx d-flex flex-column'>
							<h4 className='align-self-center mb-3'>
								Pre-sale is Live Now
							</h4>
							<div class='row prSlR'>
								<div class='col-sm-12 col-md-auto col-lg-auto prSlC'>
									<h5>Pre-sale Ends on May</h5>
									<div class='prSlTmR'>
										<div class='prSlTmC'>
											<div class='prSlTmNb'>{days}</div>
											<div class='prSlTmLb'>Days</div>
										</div>

										<div class='prSlTmC'>
											<div class='prSlTmNb'>{hours}</div>
											<div class='prSlTmLb'>Hours</div>
										</div>

										<div class='prSlTmC'>
											<div class='prSlTmNb'>
												{minutes}
											</div>
											<div class='prSlTmLb'>Minutes</div>
										</div>

										<div class='prSlTmC'>
											<div class='prSlTmNb'>
												{seconds}
											</div>
											<div class='prSlTmLb'>Seconds</div>
										</div>
									</div>
								</div>
								<div class='col-sm-12 col-md col-lg-6 prSlC'>
									<h5>Pre sale</h5>
									<div class='prSlPrg'>
										<div class='progress'>
											<div
												class='progress-bar'
												style={{
													width: `${supplyData.getSupply}%`
												}}
												role='progressbar'
												aria-valuenow='0'
												aria-valuemin='0'
												aria-valuemax='100'
											></div>
										</div>
										<div class='prSlPrgTx'>
											<span>{currentSupply && currentSupply} BSBT</span>
											<span>
												{supplyData.totalSupply} BSBT
											</span>
										</div>
									</div>
									<h5 className='mt-3'>
										Buy Before Price Increases
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class='orCn'>
					<div class='orCnOl'>
						<img src={orCnOl} alt='orCnOl' />
					</div>
					<div class='container'>
						<div class='orCnHd secHd'>
							<h2
								class='secTl'
								dangerouslySetInnerHTML={{
									__html: cms[1]?.title
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: cms[1]?.content_description
								}}
							/>
						</div>

						<div class='orCnBd'>
							<div class='row orCnBdR'>
								<div class='col-sm-12 col-md-6 col-lg-6 orCnBdC'>
									<div class='orCnCon'>
										<div class='orCnIc'>
											<img src={userexp} alt='user-exp' />
										</div>
										<h5
											dangerouslySetInnerHTML={{
												__html: coinInfo.title_1
											}}
										/>
										<p
											dangerouslySetInnerHTML={{
												__html: coinInfo.content_1
											}}
										/>
									</div>
								</div>

								<div class='col-sm-12 col-md-6 col-lg-6 orCnBdC'>
									<div class='orCnCon'>
										<div class='orCnIc'>
											<img src={quality} alt='quality' />
										</div>
										<h5
											dangerouslySetInnerHTML={{
												__html: coinInfo.title_2
											}}
										/>
										<p
											dangerouslySetInnerHTML={{
												__html: coinInfo.content_2
											}}
										/>
									</div>
								</div>

								<div class='col-sm-12 col-md-6 col-lg-6 orCnBdC'>
									<div class='orCnCon'>
										<div class='orCnIc'>
											<img src={trust} alt='trust' />
										</div>
										<h5
											dangerouslySetInnerHTML={{
												__html: coinInfo.title_3
											}}
										/>
										<p
											dangerouslySetInnerHTML={{
												__html: coinInfo.content_3
											}}
										/>
									</div>
								</div>

								<div class='col-sm-12 col-md-6 col-lg-6 orCnBdC'>
									<div class='orCnCon'>
										<div class='orCnIc'>
											<img src={lowfees} alt='low-fees' />
										</div>
										<h5
											dangerouslySetInnerHTML={{
												__html: coinInfo.title_4
											}}
										/>
										<p
											dangerouslySetInnerHTML={{
												__html: coinInfo.content_4
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section class='coin-wrapper'>
					<div class='container'>
						<div class='heading'>
							<h1
								dangerouslySetInnerHTML={{
									__html: cms[2]?.title
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: cms[2]?.content_description
								}}
							/>
						</div>
						<div class='coin-box-wrapper'>
							<div class='coin-box'>
								<h2>
									1<span class='dot'></span>
								</h2>
								<h5
									dangerouslySetInnerHTML={{
										__html: coinStructure.title_1
									}}
								/>
							</div>
							<div class='coin-box blue'>
								<h2>
									2<span class='dot'></span>
								</h2>
								<h5
									dangerouslySetInnerHTML={{
										__html: coinStructure.title_2
									}}
								/>
							</div>
							<div class='coin-box yellow'>
								<h2>
									3<span class='dot'></span>
								</h2>
								<h5
									dangerouslySetInnerHTML={{
										__html: coinStructure.title_3
									}}
								/>
							</div>
							<div class='coin-box red'>
								<h2>
									4<span class='dot'></span>
								</h2>
								<h5
									dangerouslySetInnerHTML={{
										__html: coinStructure.title_4
									}}
								/>
							</div>
						</div>
					</div>
				</section>
				<div class='mLsU'>
					<div class='container'>
						<div class='mLsUHd secHd'>
							<h2
								class='secTl'
								dangerouslySetInnerHTML={{
									__html: moreLikelyToSignupContents?.heading
								}}
							></h2>
							<p
								dangerouslySetInnerHTML={{
									__html: moreLikelyToSignupContents?.sub_heading
								}}
							></p>
						</div>

						<div class='mLsUBd'>
							<div class='mLsUTb'>
								<div class='row'>
									<div class='col-lg-auto'>
										<Nav tabs className='flex-column'>
											<NavItem>
												<NavLink
													className={classnames({
														active:
															activeTab === '1'
													})}
													onClick={() => {
														toggletab('1')
													}}
												>
													Rewards
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({
														active:
															activeTab === '2'
													})}
													onClick={() => {
														toggletab('2')
													}}
												>
													Win
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({
														active:
															activeTab === '3'
													})}
													onClick={() => {
														toggletab('3')
													}}
												>
													Earn
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({
														active:
															activeTab === '4'
													})}
													onClick={() => {
														toggletab('4')
													}}
												>
													Our Product
												</NavLink>
											</NavItem>
										</Nav>
									</div>
									<div class='col-lg'>
										<div class='mLsUTbCon'>
											<TabContent activeTab={activeTab}>
												<TabPane tabId='1'>
													<div class='row align-items-center'>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuIgOl'>
																<img
																	src={mrlk}
																	alt='moreLikeOl'
																/>
															</div>
															<div class='mlSuIg'>
																<img
																	src={strrbn}
																	alt='star-ribbon'
																/>
															</div>
														</div>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuTx'>
																<h5>
																	{
																		moreLikelyToSignupContents?.title_1
																	}
																</h5>
																<p>
																	{
																		moreLikelyToSignupContents?.content_1
																	}
																</p>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId='2'>
													<div class='row align-items-center'>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuIgOl'>
																<img
																	src={mrlk}
																	alt='moreLikeOl'
																/>
															</div>
															<div class='mlSuIg'>
																<img
																	src={mny3d}
																	alt='money-3d'
																/>
															</div>
														</div>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuTx'>
																<h5>
																	{
																		moreLikelyToSignupContents?.title_2
																	}
																</h5>
																<p>
																	{
																		moreLikelyToSignupContents?.content_2
																	}
																</p>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId='3'>
													<div class='row align-items-center'>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuIgOl'>
																<img
																	src={mrlk}
																	alt='moreLikeOl'
																/>
															</div>
															<div class='mlSuIg'>
																<img
																	src={mnybg}
																	alt='money-bag'
																/>
															</div>
														</div>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuTx'>
																<h5>
																	{
																		moreLikelyToSignupContents?.title_3
																	}
																</h5>
																<p>
																	{
																		moreLikelyToSignupContents?.content_3
																	}
																</p>
															</div>
														</div>
													</div>
												</TabPane>
												<TabPane tabId='4'>
													<div class='row align-items-center'>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuIgOl'>
																<img
																	src={mrlk}
																	alt='moreLikeOl'
																/>
															</div>
															<div class='mlSuIg'>
																<img
																	src={mny3d2}
																	alt='money-bag'
																/>
															</div>
														</div>
														<div class='col-sm-12 col-md-6 col-lg-6'>
															<div class='mlSuTx'>
																<h5>
																	{
																		moreLikelyToSignupContents?.title_4
																	}
																</h5>
																<p>
																	{
																		moreLikelyToSignupContents?.content_4
																	}
																</p>
															</div>
														</div>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class='token-wrapper mb-4'>
					<div class='container'>
						<div class='heading'>
							<h1
								dangerouslySetInnerHTML={{
									__html: cms[4]?.title
								}}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: cms[4]?.content_description
								}}
							/>
						</div>

						<div class='tknDst'>
							<div class='tknChDt'>
								<div class='row tknChDtR'>
									<div class='col-sm-12 col-md-12 col-lg order-2 order-lg-1 tknChDtC'>
										<div class='tknChrt'>
											<Chart
												options={chartinfo}
												series={chartinfo.series}
												type='donut'
											/>
										</div>
									</div>
									<div class='col-sm-12 col-md-12 col-lg-auto order-1 order-lg-2 tknChDtC'>
										<div class='tknDt'>
											<div class='tknDtR'>
												<div class='tknDtC'>
													<div class='tknDtLb'>
														{
															tokenDistributionInfo?.tokenSymbol
														}
													</div>
													<div class='tknDtVl'>
														Token Name
													</div>
												</div>

												<div class='tknDtC'>
													<div class='tknDtLb'>
														{
															tokenDistributionInfo?.tokenType
														}
													</div>
													<div class='tknDtVl'>
														Type
													</div>
												</div>

												<div class='tknDtC'>
													<div class='tknDtLb'>
														{
															tokenDistributionInfo?.tokenName
														}
													</div>
													<div class='tknDtVl'>
														Token Name
													</div>
												</div>

												<div class='tknDtC'>
													<div class='tknDtLb'>
														{
															tokenDistributionInfo?.totalSupply
														}
													</div>
													<div class='tknDtVl'>
														Total Supply
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class='prcDt'>
								<div class='row prcDtR'>
									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='15'
														height='24'
														viewBox='0 0 15 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M3.75729 0.092041C7.67965 2.4344 10.7598 5.95838 12.5563 10.1589C14.3527 14.3594 14.7733 19.0208 13.7576 23.4751L6.66848 21.8586C7.34277 18.9015 7.06357 15.8068 5.87091 13.0181C4.67824 10.2293 2.63335 7.88978 0.0292969 6.33469L3.75729 0.092041Z'
															fill='#DE15D6'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.ito_presale
														}
														%
													</div>
													<div class='prcDtLb'>
														ICO
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.ito_presale_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='11'
														height='26'
														viewBox='0 0 11 26'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M6.70908 0.485107C9.39926 4.17761 10.8514 8.62698 10.8574 13.1955C10.8634 17.7641 9.42297 22.2172 6.7425 25.9168L0.854463 21.6507C2.63402 19.1946 3.59033 16.2381 3.58635 13.2051C3.58236 10.172 2.61828 7.21811 0.832275 4.76667L6.70908 0.485107Z'
															fill='#3D15DE'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.marketing_cex
														}
														%
													</div>
													<div class='prcDtLb'>
														Open Supply & Liquidity
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.marketing_cex_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='18'
														height='22'
														viewBox='0 0 18 22'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M17.2984 0.820371C17.2657 5.3888 15.7875 9.82961 13.0758 13.5063C10.3641 17.183 6.558 19.9069 2.20305 21.2875L0.0057373 14.3564C2.89697 13.4398 5.42382 11.6314 7.22412 9.19048C9.02443 6.74952 10.0058 3.80128 10.0275 0.768311L17.2984 0.820371Z'
															fill='#B5A3FF'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.development
														}
														%
													</div>
													<div class='prcDtLb'>
														Management & Founders
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.development_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='24'
														height='15'
														viewBox='0 0 24 15'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M23.3581 3.4293C21.2111 7.46191 17.8426 10.7113 13.7353 12.7118C9.628 14.7122 4.99284 15.3611 0.494141 14.5653L1.76069 7.40534C4.74736 7.93367 7.82463 7.50291 10.5514 6.17481C13.2783 4.84671 15.5146 2.68944 16.94 0.012207L23.3581 3.4293Z'
															fill='#D92D6B'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.advisiory_team
														}
														%
													</div>
													<div class='prcDtLb'>
														Marketing & CEX
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.advisiory_team_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='26'
														height='13'
														viewBox='0 0 26 13'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M25.2678 10.2639C21.0922 12.1174 16.4369 12.6014 11.9693 11.6465C7.50163 10.6916 3.45096 8.34686 0.397827 4.94833L5.80676 0.0891113C7.83373 2.34539 10.523 3.90205 13.489 4.53599C16.4551 5.16993 19.5457 4.84861 22.3179 3.61808L25.2678 10.2639Z'
															fill='#2DD9BA'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.liquidity_openSupply
														}
														%
													</div>
													<div class='prcDtLb'>
														Developers
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.liquidity_openSupply_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='15'
														height='25'
														viewBox='0 0 15 25'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M10.5987 24.5973C6.7968 22.0641 3.89444 18.3924 2.3076 14.1083C0.72077 9.82415 0.53093 5.14766 1.76529 0.749023L8.76594 2.71357C7.94645 5.63381 8.07249 8.73853 9.12598 11.5827C10.1795 14.4269 12.1063 16.8646 14.6304 18.5464L10.5987 24.5973Z'
															fill='#C1C502'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.playersReward
														}
														%
													</div>
													<div class='prcDtLb'>
														Maintenance and IT
														support
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.playersReward_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='21'
														height='20'
														viewBox='0 0 21 20'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M0.905762 17.6294C1.8451 13.1585 4.17574 9.09968 7.56363 6.03475C10.9515 2.96982 15.2227 1.0561 19.7651 0.567871L20.5422 7.79731C17.5265 8.12144 14.6908 9.39196 12.4416 11.4268C10.1924 13.4616 8.64511 16.1562 8.02149 19.1244L0.905762 17.6294Z'
															fill='#7339EF'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.server_maintanence
														}
														%
													</div>
													<div class='prcDtLb'>
														Advisory Committee
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.server_maintanence_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='23'
														height='17'
														viewBox='0 0 23 17'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M0.599609 13.5807C2.307 9.34323 5.31206 5.75502 9.18411 3.33038C13.0562 0.905744 17.5964 -0.230843 22.1541 0.0835174L21.6538 7.33736C18.6279 7.12866 15.6137 7.88324 13.043 9.49295C10.4724 11.1027 8.47735 13.4849 7.34382 16.2981L0.599609 13.5807Z'
															fill='#DE15D6'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.founder_mangement
														}
														%
													</div>
													<div class='prcDtLb'>
														Reward & Incentives
													</div>
													<div class='prcDtVl'>
														{
															tokenDistributionInfo?.founder_mangement_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='15'
														height='24'
														viewBox='0 0 15 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M3.75729 0.092041C7.67965 2.4344 10.7598 5.95838 12.5562 10.1589C14.3527 14.3594 14.7732 19.0208 13.7576 23.4751L6.66848 21.8586C7.34277 18.9015 7.06357 15.8068 5.8709 13.018C4.67824 10.2293 2.63335 7.88978 0.0292969 6.33469L3.75729 0.092041Z'
															fill='#DE15D6'
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.charity
														}
														%
													</div>
													<div class='prcDtLb'>
														Company Social
														Responsibility
													</div>
													<div class='prcDtVl'>
														{' '}
														{
															tokenDistributionInfo?.charity_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class='col-sm-6 col-md-4 col-lg prcDtC'>
										<div class='prcDtCon'>
											<div class='prcDtConR'>
												<div class='prcDtConIg'>
													<svg
														width='23'
														height='17'
														viewBox='0 0 23 17'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M0.599609 13.5807C2.307 9.34323 5.31206 5.75502 9.18411 3.33038C13.0562 0.905744 17.5964 -0.230843 22.1541 0.0835174L21.6538 7.33736C18.6279 7.12866 15.6137 7.88324 13.043 9.49295C10.4724 11.1027 8.47735 13.4849 7.34382 16.2981L0.599609 13.5807Z'
															fill='#FFD056 '
														/>
													</svg>
												</div>
												<div class='prcDtConTx'>
													<div class='prcDtNb'>
														{
															tokenDistributionInfo?.gaming_events
														}
														%
													</div>
													<div class='prcDtLb'>
														Promotions and Events
													</div>
													<div class='prcDtVl'>
														{' '}
														{
															tokenDistributionInfo?.gaming_events_value
														}{' '}
														Token
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class='road-bg' ref={roadMaprRef} id='roadmap'>
					<section class='road-map-wrapper'>
						<div class='container'>
							<div class='heading'>
								<h1
									dangerouslySetInnerHTML={{
										__html: cms[3]?.title
									}}
								/>
								<p
									dangerouslySetInnerHTML={{
										__html: cms[3]?.content_description
									}}
								/>
							</div>

							<Slider {...settings} className='roadmap'>
								{roadMap &&
									roadMap.map((d, i) => {
										return (
											<div class='item' key={i}>
												<div className='itemcnt'>
													<div class='item-bar'>
														<div class='year'>
															<h1>{d.year}</h1>
														</div>
														<div class='item-details'>
															<h2>{d.title}</h2>
															<p>{d.content}</p>
														</div>
													</div>
												</div>
											</div>
										)
									})}
							</Slider>
						</div>
					</section>

					<section className='faqsec mb-5'>
						<div className='container'>
							<div className='faqsecbg'>
								<div class='row'>
									<div class='col-md-4'>
										<div class='ger_aq'>
											<h2>
												Frequently Asked <br />
												<span>Questions</span>
											</h2>
										</div>
									</div>
									<div class='col-md-8'>
										<div class='faq_toatl'>
											<Accordion allowZeroExpanded>
												{faq &&
													faq.map((q, index) => {
														const {
															question,
															description
														} = q
														return (
															<AccordionItem
																className='card card1'
																key={index}
															>
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{
																			question
																		}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<p
																		dangerouslySetInnerHTML={{
																			__html: description
																		}}
																	/>
																</AccordionItemPanel>
															</AccordionItem>
														)
													})}
											</Accordion>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<div class='acrGl'>
						<div class='container'>
							<div class='acrGlHd secHd'>
								<h2
									class='secTl'
									dangerouslySetInnerHTML={{
										__html: cms[6]?.title
									}}
								></h2>
								<p
									dangerouslySetInnerHTML={{
										__html: cms[6]?.content_description
									}}
								></p>
							</div>
							<div class='acrGlBd'>
								<Slider class='acrGlR' {...settings1}>
									{featuredAcrossTheGlobeContents &&
										featuredAcrossTheGlobeContents.map(
											(f, i) => {
												return (
													<a
														key={i}
														href={f.link}
														target='_blank'
													>
														<div class='arcGlC'>
															<div class='arcGlIg'>
																<img
																	src={f.img}
																	alt='economics'
																	style={{
																		height: '29px',
																		objectFit:
																			'contain'
																	}}
																/>
															</div>
														</div>
													</a>
												)
											}
										)}
								</Slider>
							</div>
						</div>
					</div>

					<section class='newsletter-wrapper' ref={whitePaperRef}>
						<div class='container' id='whitepaper'>
							<div class='news-letter-box'>
								<div class='heading'>
									<h4
										dangerouslySetInnerHTML={{
											__html: cms[5]?.title
										}}
									/>
									<p
										dangerouslySetInnerHTML={{
											__html: cms[5]?.content_description
										}}
									/>
								</div>
								<div class=''>
									<a
										target='_blank'
										href={siteInfo?.whitepaper_Url}
										class='btn btn-14748 whitepaper-btn'
									>
										Whitepaper
									</a>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<Modal isOpen={modal} toggle={toggle} className='bigShot-modal'>
				<ModalHeader toggle={toggle}>
					{' '}
					How to <span>Buy ?</span>
					<p>{howToBuyContents?.sub_heading}</p>
				</ModalHeader>
				<ModalBody>
					<ul class='big-style' role='list'>
						<li>
							<h6>{howToBuyContents?.title_1}</h6>
							<p>{howToBuyContents?.content_1}</p>
						</li>
						<li>
							<h6>{howToBuyContents?.title_2}</h6>
							<p>{howToBuyContents?.content_2}</p>
						</li>
						<li>
							<h6>{howToBuyContents?.title_3}</h6>
							<p>{howToBuyContents?.content_3}</p>
						</li>
						<li>
							<h6>{howToBuyContents?.title_4}</h6>
							<p>{howToBuyContents?.content_4}</p>
						</li>
					</ul>
				</ModalBody>
				<ModalFooter>
					<button
						type='button'
						class='btn btn-submit mb-2'
						onClick={toggle}
					>
						Close
					</button>
				</ModalFooter>
			</Modal>

			{/* <Modal
				isOpen={modal1}
				toggle={toggleLaunch}
				className='bigShot-modal modal-dialog-centered'
			>
				<ModalBody>
					<div class='timer-bg '>
						<div class='modal-header'>
							<div class='brand mx-auto'>
								<img src={logobig} alt='' />
							</div>
							<div class='logo-head'>
								<h2>
									Launching the
									<span>Big Shot</span>
								</h2>
								<h3>Buy Before Price increases</h3>
							</div>
						</div>
						<div class='modal-body'>
							<div class='timer-wrapper'>
								<div class='time-box'>
									<h1>{days}</h1>
									<p>Days</p>
								</div>
								<div class='time-box'>
									<h1>{hours}</h1>
									<p>Hours</p>
								</div>
								<div class='time-box'>
									<h1>{minutes}</h1>
									<p>Minutes</p>
								</div>
								<div class='time-box'>
									<h1>{seconds}</h1>
									<p>Seconds</p>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal> */}
		</Fragment>
	)
}

export default Home
