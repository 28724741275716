export const scrollToTop = () => {
	window.scrollTo(0, 0)
}

export const copyText = (text, cb = () => {}) => {
	if (navigator.clipboard && navigator.permissions) {
		navigator.clipboard.writeText(text).then(cb)
	} else if (document.queryCommandSupported('copy')) {
		const ele = document.createElement('textarea')
		ele.value = text
		document.body.appendChild(ele)
		ele.select()
		document.execCommand('copy')
		document.body.removeChild(ele)
		cb()
	}
}

export const formatDateToDefaultLocale = date => {
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: false
	}
	const dateObj = new Date(date)
	const formattedDate = new Intl.DateTimeFormat('default', options).format(
		dateObj
	)
	return formattedDate.split(',').join(' &')
}
