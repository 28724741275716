import React, { Fragment, useEffect, useState } from 'react'

import '../History/scss/history.scss'
import {} from 'reactstrap'
import refreshImg from '../../assets/images/refresh.svg'
import downarrowImg from '../../assets/images/downarrow.svg'
import usdtImg from '../../assets/images/usdt.svg'
import ethImg from '../../assets/images/eth.svg'
import trxImg from '../../assets/images/trx.svg'
import btcImg from '../../assets/images/ICON__1_.png'
import copyImg from '../../assets/images/copy.svg'
import leftarrowImg from '../../assets/images/leftarrow.png'
import rightarrowImg from '../../assets/images/rightarrow.png'
import { CONST } from '../../config'
import axios from 'axios'
import { accountChange, ChangeNetwork } from '../../common/Services'
import { Link, useNavigate } from 'react-router-dom'
import {
	Navbar,
	NavbarBrand,
	Nav,
	NavItem,
	Modal,
	ModalHeader,
	ModalBody,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Collapse
} from 'reactstrap'
import contractDetails from '../../common/contracts'
import BSCContractDetails from '../../common/BscContract'
import ETHContractDetails from '../../common/EthContract'
import Web3 from 'web3'
import FadeLoader from 'react-spinners/FadeLoader'
import { toast } from 'react-toastify'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'
import WalletConnectProvider from '@walletconnect/web3-provider'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import bigShotClaimAbi from '../../common/abi/bigShotAiClaim.json'
import bsbtImg from '../../assets/images/bigshot coin 1.png'

const History = props => {
	let web3
	const [history, setHistory] = useState([])
	const [loading, setLoading] = useState(false)
	const [loading1, setLoading1] = useState(false)
	const [claim_history, setClaimHistory] = useState([])
	const [monthDiffer, setMountDiffer] = useState('')
	const navigate = useNavigate()
	const [color, setColor] = useState('#5AC41E')
	const [claimStatus, setClaimStatus] = useState(false)

	useEffect(() => {
		getHistory()
		accountChange.changeAccount()
		getClaimHistory()
		getStakeHistory()
		// window.scrollTo(0, 0)

		if (!sessionStorage.getItem('bigShotAccount')) {
			navigate(-1)
		}
	}, [])

	const [isOpen, setIsOpen] = useState(null)
	const [isOpen1, setIsOpen1] = useState(false)
	const [stakehistory, setStakeHisory] = useState([])

	const toggle = (data, data1) => {
		setIsOpen(data1)
	}

	const getStakeHistory = async (data, data1) => {
		setLoading1(true)
		let data2 = {
			address: sessionStorage.getItem('bigShotAccount')
		}

		let stakeHistory = await axios.post(
			CONST.BACKEND_URL + '/ico/claimHistory',
			data2
		)

		if (stakeHistory.data.status == 200) {
			setStakeHisory(stakeHistory.data.claimData)
			setLoading1(false)
		}
	}

	const backbutton = () => {
		navigate('/buytoken')
	}

	const getHistory = async () => {
		let address = {
			address: sessionStorage.getItem('bigShotAccount').toLowerCase()
		}
		let getdata = await axios.post(
			CONST.BACKEND_URL + '/ico/buytokenHistory',
			address
		)

		if (getdata.data.status == 200) {
			setHistory(getdata.data.data)
			setLoading(false)

			for (let i = 0; i <= getdata.data.data.length - 1; i++) {}
		}
	}

	const getClaimHistory = async () => {
		let address = {
			address: sessionStorage.getItem('bigShotAccount').toLowerCase()
		}
		let getdata = await axios.post(
			CONST.BACKEND_URL + '/ico/claimData',
			address
		)

		setClaimHistory(getdata.data.claimData)
		setClaimStatus(getdata.data.claimStatus)
	}

	const claim = async data => {
		setLoading(true)
		const walletType = localStorage.getItem('wallet')
		if (walletType === 'Metamask' || walletType === 'Trust') {
			web3 = new Web3(window.ethereum)
		} else {
			const provider = new WalletConnectProvider({
				rpc: {
					56: 'https://bsc-dataseed1.ninicoin.io',
					1: 'https://mainnet.infura.io/v3/'
				},
				qrcode: true,
				qrcodeModalOptions: {
					mobileLinks: ['trust', 'metamask']
				}
			})
			web3 = new Web3(provider)
		}
		try {
			await ChangeNetwork.Bnbnetwork()
			const claimContract = new web3.eth.Contract(
				bigShotClaimAbi,
				contractDetails.claim
			)
			let amount = data.claimAmount * 1e18
			const deadline = (new Date().getTime() + 10000).toString()
			const hash = await claimContract.methods
				.prepareClaimHash(
					sessionStorage.getItem('bigShotAccount'),
					amount.toString(),
					deadline
				)
				.call()
			const res = await axios.post(CONST.BACKEND_URL + '/ico/claimVrs', {
				claimHash: hash
			})
			const tx = await claimContract.methods
				.claimToken(
					amount.toString(),
					deadline,
					res.data.v,
					res.data.r,
					res.data.s
				)
				.send({ from: sessionStorage.getItem('bigShotAccount') })

			axios
				.post(CONST.BACKEND_URL + '/ico/claimDataUpdate', {
					address: sessionStorage.getItem('bigShotAccount'),
					claimID: data._id,
					txnid: tx.transactionHash
				})
				.then(res => {
					if (res.status === 200) {
						toast.success('Claim successful')
						getClaimHistory()
						getStakeHistory()
						setLoading(false)
					}
				})
		} catch (err) {
			toast.error('Transaction rejected')
			setLoading(false)
		}
	}

	return (
		<Fragment>
			<div className={loading ? 'spinner' : 'd-none'}>
				<div>
					<FadeLoader color={color} size={50} />
				</div>
			</div>

			<div
				className='cntld histCnt InnrMain'
				style={{ background: 'black' }}
			>
				<div className='histpg'>
					<div className='container'>
						<div className='row justify-content-center align-items-center mt-5'>
							<div className='col-lg-10 px-0'>
								<button
									className='back-btn'
									onClick={backbutton}
								>
									<i class='fas fa-arrow-circle-left'></i>{' '}
									Back
								</button>
							</div>

							<div className='col-lg-10 histtab p-0 mt-2'>
								<div className='backbtn-head'>
									<h3 className='m-0'>Buy History</h3>
								</div>

								<div className='table-responsive wrapper1 buyhist-tbl'>
									<table className='table mb-0'>
										<thead className='sticky'>
											<th className='align-center'>
												S.No
											</th>
											<th className='align-center'>ID</th>
											<th className='align-center'>
												Currency
											</th>
											<th className='align-center'>
												Transaction hash
											</th>
											<th className='align-center'>
												Token bought
											</th>
											<th className='align-center'>
												Date
											</th>
										</thead>
										<tbody>
											{history && history.length > 0 ? (
												history &&
												history.map((data, index) => (
													<>
														<tr key={index}>
															<td className='align-center'>
																{index + 1}
															</td>
															<td className='align-center'>
																{data.seqBuy_id}
															</td>

															<td className='align-center'>
																<span className='ms-2'>
																	{data.spend_amount ==
																		'' ||
																	!data.spend_amount ? (
																		<></>
																	) : (
																		parseFloat(
																			data.spend_amount
																		).toFixed(
																			4
																		)
																	)}
																</span>{' '}
																{data.currency}
															</td>

															<td className='align-center'>
																{data.txn_id ==
																	'' ||
																data.txn_id ==
																	null ? (
																	<>-</>
																) : (
																	data.txn_id.slice(
																		0,
																		4
																	) +
																	'...' +
																	data.txn_id.slice(
																		15,
																		19
																	)
																)}
																{data.txn_id ==
																	'' ||
																data.txn_id ==
																	null ? (
																	<></>
																) : data.network_type ===
																  'BSC' ? (
																	<a
																		href={`https://bscscan.com/tx/${data.txn_id}`}
																		target='_blank'
																	>
																		<img
																			src={
																				copyImg
																			}
																			className='ms-2'
																		/>
																	</a>
																) : (
																	<a
																		href={`https://etherscan.io/tx/${data.txn_id}`}
																		target='_blank'
																	>
																		<img
																			src={
																				copyImg
																			}
																			className='ms-2'
																		/>
																	</a>
																)}
															</td>
															<td className='align-center'>
																<img
																	src={
																		bsbtImg
																	}
																	className='icon-size'
																/>
																<span className='ms-2'>
																	{
																		data.request_amount
																	}{' '}
																	Big Shot
																</span>
															</td>
															<td className='align-center'>
																{data.created ? (
																	moment(
																		data.created
																	).format(
																		'MM/DD/YYYY'
																	)
																) : (
																	<>-</>
																)}
															</td>
														</tr>
													</>
												))
											) : (
												<>
													<tr>
														<td
															colspan='6'
															className='text-center'
														>
															<span className='nodata'>
																No Data Found
															</span>
														</td>
													</tr>
												</>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div className='row justify-content-center align-items-center mt-5'>
							<div className='col-lg-10 histtab p-0'>
								<div className='backbtn-head'>
									<h3 className='m-0'>Claim Details</h3>
								</div>

								<div className='table-responsive wrapper1 his-tblresponsive buyhist-tbl'>
									<table className='table mb-0'>
										<thead className='sticky'>
											<th className='align-center'>
												S.No
											</th>
											<th className='align-center'>ID</th>
											<th className='align-center'>
												Buy ID
											</th>

											<th className='align-center'>
												Claim amount
											</th>
											{claimStatus && (
												<th className='align-center'>
													Action
												</th>
											)}
										</thead>
										<tbody>
											{claim_history &&
											claim_history.length > 0 ? (
												claim_history &&
												claim_history.map(
													(data, index) => (
														<>
															<tr key={index}>
																<td className='align-center'>
																	{index + 1}
																</td>
																<td className='align-center'>
																	{
																		data.claimId
																	}
																</td>
																<td className='align-center'>
																	{
																		data.seqBuy_id
																	}
																</td>
																<td className='align-center'>
																	<img
																		src={
																			bsbtImg
																		}
																		className='icon-size'
																	/>
																	<span className='ms-2'>
																		{
																			data.claimAmount
																		}{' '}
																		Big Shot
																	</span>
																</td>
																{claimStatus && (
																	<td className='align-center'>
																		{data.withdraw_count ==
																		'12' ? (
																			<p
																				style={{
																					color: 'lightgreen'
																				}}
																			>
																				Claimed
																			</p>
																		) : (
																			<button
																				className='btn btn-gold whitepaper-btn'
																				onClick={() =>
																					claim(
																						data
																					)
																				}
																			>
																				Claim
																			</button>
																		)}
																	</td>
																)}
															</tr>
														</>
													)
												)
											) : (
												<>
													<tr>
														<td
															colspan='5'
															className='text-center'
														>
															<span className='nodata'>
																No Data Found
															</span>
														</td>
													</tr>
												</>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className='row justify-content-center align-items-center mt-5'>
							<div className='col-lg-10 histtab p-0 mt-2'>
								<div className='backbtn-head'>
									<h3 className='m-0'>Claim History</h3>
								</div>

								<div className='table-responsive wrapper1 buyhist-tbl'>
									<table className='table mb-0'>
										<thead className='sticky'>
											<th className='align-center'>
												S.No
											</th>
											<th className='align-center'>ID</th>
											<th className='align-center'>
												Transaction hash
											</th>
											<th className='align-center'>
												Token claimed
											</th>
											<th className='align-center'>
												Date
											</th>
										</thead>
										<tbody>
											{stakehistory &&
											stakehistory.length > 0 ? (
												stakehistory &&
												stakehistory.map(
													(data, index) => {
														return (
															<>
																<tr key={index}>
																	<td className='align-center'>
																		{index +
																			1}
																	</td>
																	<td className='align-center'>
																		{
																			data.claimId
																		}
																	</td>
																	<td className='align-center'>
																		{data.transactionHash ==
																			'' ||
																		data.transactionHash ==
																			null ? (
																			<>
																				-
																			</>
																		) : (
																			data.transactionHash.slice(
																				0,
																				4
																			) +
																			'...' +
																			data.transactionHash.slice(
																				15,
																				19
																			)
																		)}
																		{data.transactionHash ==
																			'' ||
																		data.transactionHash ==
																			null ? (
																			<>

																			</>
																		) : (
																			<a
																				href={`https://bscscan.com/tx/${data.transactionHash}`}
																				target='_blank'
																			>
																				<img
																					src={
																						copyImg
																					}
																					className='ms-2'
																				/>
																			</a>
																		)}
																	</td>

																	<td className='align-center'>
																		<img
																			src={
																				bsbtImg
																			}
																			className='icon-size'
																		/>
																		<span className='ms-2'>
																			{
																				data.claimAmount
																			}{' '}
																			Big
																			Shot
																		</span>
																	</td>
																	<td className='align-center'>
																		{data.claimedDate ? (
																			moment(
																				data.claimedDate
																			).format(
																				'MM/DD/YYYY'
																			)
																		) : (
																			<>
																				-
																			</>
																		)}
																	</td>
																</tr>
															</>
														)
													}
												)
											) : (
												<>
													<tr>
														<td
															colspan='5'
															className='text-center'
														>
															<span className='nodata'>
																No Data Found
															</span>
														</td>
													</tr>
												</>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default History
