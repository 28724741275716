import { useEffect, useRef, useState } from 'react'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	
} from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import Home from '../src/views/HomeBS/index.js'
import { Home1 } from '../src/views/Buytoken/Buytoken'
import History from '../src/views/History/History'
import Pagenotfound from '../src/views/Pagenotfound/Pagenotfound'
import Underconstruction from '../src/views/underconstruction'
import { CONST } from './config'
import Idle from './common/siteidle'
import Footer from './components/Footer'
import Header from './components/Header'
import connectWalletModalService from './common/connectWalletModalService'
import ContactUs from './views/contact-us'
import ReferralPage from './views/referral'

import './App.css'

const App = props => {


	useEffect(() => {
		document.addEventListener('contextmenu', event =>
			event.preventDefault()
		)
	}, [])

	// scrollStuff
	const homeRef = useRef(null)
	const handleHomeBtn = () =>
		homeRef.current.scrollIntoView({ behavior: 'smooth' })

	const whitePaperRef = useRef(null)
	const handleWhitePaperBtn = () =>
		whitePaperRef.current.scrollIntoView({ behavior: 'smooth' })

	const roadMaprRef = useRef(null)
	const handleRoadMaprBtn = () =>
		roadMaprRef.current.scrollIntoView({ behavior: 'smooth' })

	const contactUsRef = useRef(null)
	const handleContactUsBtn = () =>
		contactUsRef.current.scrollIntoView({ behavior: 'smooth' })

	const [isLogin, setIsLogin] = useState(
		sessionStorage.getItem('bigShotAccount')
	)
	const [isCurrency, setIsCurrency] = useState('')
	const [profilePic, setProfilePic] = useState('')
	const [isLogin1, setIsLogin1] = useState(
		localStorage.getItem('bigshotloginId')
	)
	const [maintananceDetail, setMaintananceDetail] = useState('')
	const [account, setAccount] = useState(sessionStorage.getItem('account'))

	const [logo, setLogo] = useState('')
	const [siteInfo, setSiteInfo] = useState({})
	const [sectionToScroll, setSectionToScroll] = useState('')
	const [refId, setRefId] = useState('')

	useEffect(() => {
		getSiteInform()
		document.cookie = 'cookie1=test; path=/'

		if (sessionStorage.getItem('bigshotloginId')) {
			setIsLogin(sessionStorage.getItem('bigshotloginId'))
		}

		if (sessionStorage.getItem('account')) {
			setAccount(sessionStorage.getItem('account'))
		}

		if (localStorage.getItem('bigshotloginId')) {
			setIsLogin1(localStorage.getItem('bigshotloginId'))
			sessionStorage.setItem(
				'bigshotloginId',
				localStorage.getItem('bigshotloginId')
			)
			setIsLogin(localStorage.getItem('bigshotloginId'))
		}
	}, [])

	const getSiteInform = async () => {
		let getData = await axios.get(CONST.BACKEND_URL + '/ico/site_inform')

		if (getData.data.status === true) {
			setSiteInfo(getData?.data?.data)
			setMaintananceDetail(getData.data.data.maintance_detail)
			// document
			// 	.getElementById('favicon')
			// 	.setAttribute('href', getData.data.data.site_favicon)
			setLogo(getData.data.data.site_favicon)
			if (getData.data.data.maintance_detail == 'undermaintanence') {
				return <Navigate to='/underconstruction' />
			}
		}
	}

	useEffect(() => {
		const subscription = connectWalletModalService
			.getMessage()
			.subscribe(({ component }) => {
				setSectionToScroll(component)
			})

		return () => {
			subscription.unsubscribe()
		}
	}, [])

	return (
		<>
			<Idle />

			<Helmet>
				{' '}
				<meta charSet='utf-8' /> <link rel='icon' href={logo} />{' '}
				<title>Big Shot</title> <meta data-react-helmet='true' />{' '}
				<meta
					name='theme-color'
					content='#008f68'
					data-react-helmet='true'
				/>{' '}
			</Helmet>


			<Router>
				<Header
					handleWhitePaperBtn={handleWhitePaperBtn}
					handleRoadMaprBtn={handleRoadMaprBtn}
					handleContactUsBtn={handleContactUsBtn}
					handleHomeBtn={handleHomeBtn}
					whitePaperRef={whitePaperRef}
					refId={refId}
				/>

				<Routes history={props.history}>
					<Route
						path='/'
						element={
							<Home
								whitePaperRef={whitePaperRef}
								roadMaprRef={roadMaprRef}
								homeRef={homeRef}
								handleWhitePaperBtn={handleWhitePaperBtn}
								sectionToScroll={sectionToScroll}
								setRefId={setRefId}
							/>
						}
					/>
					<Route path='/history' element={<History />} />
					<Route path='/buytoken' element={<Home1 />} />
					<Route path='/contact-us' element={<ContactUs />} />
					<Route path='/pagenotfound' element={<Pagenotfound />} />
					<Route path='/referral' element={<ReferralPage />} />

					<Route path='/referral/:id' element={
						<Home
						whitePaperRef={whitePaperRef}
						roadMaprRef={roadMaprRef}
						homeRef={homeRef}
						handleWhitePaperBtn={handleWhitePaperBtn}
						sectionToScroll={sectionToScroll}
						setRefId={setRefId}
					/>
						} />


					<Route
						path='/underconstruction'
						element={<Underconstruction />}
					/>
				</Routes>
			</Router>




			<Footer siteInfo={siteInfo} contactUsRef={contactUsRef} />
		</>
	)
}
export default App
