import React from 'react'
import Web3 from 'web3'

import ETHContractDetails from './EthContract'
import BSCContractDetails from './BscContract'
import Ethtoken from './Ethtoken.json'
import Bsctoken from './Bsctoken.json'
import { ChangeNetwork } from './Services'
import { toast } from 'react-toastify'
import bscAbi from './bscAbi.json'
import { CONST } from '../config'
import axios from 'axios'
import ethAbi from './ethAbi.json'
import tronWeb from 'tronweb'
import { Status } from '../views/Buytoken/Buytoken'
import tronAbi from './tronAbi.json'
import gusdAbi from './gusdico.json'
import Ethgusd from './Ethgusd.json'
import Bscgusd from './Bscgusd.json'

import BTYC from '../common/abi/BTYC.json'
import usdtAbi from '../common/abi/usdtAbi.json'
import bigShotClaimAbi from '../common/abi/bigShotAiClaim.json'
import contractDetails from '../common/contracts'
import WalletConnectProvider from '@walletconnect/web3-provider'

let web3

export const buyBigShotToken = async (data, data1, data2, data3, cb) => {
	const walletType = localStorage.getItem('wallet')
	if (walletType === 'Metamask' || walletType === 'Trust') {
		web3 = new Web3(window.ethereum)
	} else {
		const provider = new WalletConnectProvider({
			rpc: {
				56: 'https://bsc-dataseed1.ninicoin.io',
				1: 'https://mainnet.infura.io/v3/'
			},
			qrcode: true,
			qrcodeModalOptions: {
				mobileLinks: ['trust', 'metamask']
			}
		})
		web3 = new Web3(provider)
	}
	const amountConvert = (amount, decimal, type = 'towei') => {
		if (type == 'towei') {
			let coinwei = Math.pow(10, decimal)
			let sendAmount = amount * coinwei
			return (sendAmount = getNumber(sendAmount))
		} else if (type == 'fromwei') {
			let coinwei = Math.pow(10, decimal)
			let sendAmount = amount / coinwei
			return (sendAmount = getNumber(sendAmount))
		}
	}

	const getNumber = x => {
		if (Math.abs(x) < 1.0) {
			var e = parseInt(x.toString().split('e-')[1])
			if (e) {
				x *= Math.pow(10, e - 1)
				x = '0.' + new Array(e).join('0') + x.toString().substring(2)
			}
		} else {
			var e = parseInt(x.toString().split('+')[1])
			if (e > 20) {
				e -= 20
				x /= Math.pow(10, e)
				x += new Array(e + 1).join('0')
			}
		}
		return x
	}
	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms))
	}
	if (data == 'BTYC') {
		if (typeof window.web3 !== undefined) {
			try {
				// let amount = web3.utils.toWei((data1).toString(), 'ether');
				let amount = data1 * 1e18
				amount = getNumber(amount)
				await ChangeNetwork.Bnbnetwork()
				await sleep(3000)
				let bigTycoonContract = new web3.eth.Contract(
					BTYC,
					contractDetails.bigTycoon
				)

				let claimContract = new web3.eth.Contract(
					bigShotClaimAbi,
					contractDetails.claim
				)

				let balance = await bigTycoonContract.methods
					.balanceOf(sessionStorage.getItem('bigShotAccount'))
					.call()
				let owner = await claimContract.methods.owner().call()
				balance = web3.utils.fromWei(balance)
				balance = parseFloat(balance).toFixed(5)
				data1 = parseFloat(data1).toFixed(5)

				if (parseFloat(balance) < parseFloat(data1)) {
					toast.warning('Insufficient Funds')
					cb()
				} else {
					let buyToken = await bigTycoonContract.methods
						.transfer(owner, amount.toString())
						.send({
							from: sessionStorage.getItem('bigShotAccount')
						})

					if (buyToken.status === true) {
						let data = {
							from_address: sessionStorage
								.getItem('bigShotAccount')
								.toLowerCase(),
							to_address: owner,
							currency: 'BTYC',
							network_type: 'BSC',
							spend_amount: data1,
							txn_id: buyToken.transactionHash,
							status: 'success',
							request_amount: data2,
							user_address: ''
						}

						let send = await axios.post(
							CONST.BACKEND_URL + '/ico/buytoken',
							data
						)

						if (send.data.status == 200) {
							toast.success(send.data.message)
							cb(true)
						} else {
							cb()
						}
					} else {
						cb()
					}
				}
			} catch (err) {
				console.log(err)
				toast.error('Transaction Rejected')
				cb()
			}
		} else {
			cb()
		}
	} else if (data == 'BNB') {
		if (typeof window.web3 !== undefined) {
			try {
				await ChangeNetwork.Bnbnetwork()
				// let amount = web3.utils.toWei((data1).toString(), 'ether');
				let amount = data1 * 1e18
				await sleep(3000)
				let balance = await web3.eth.getBalance(
					sessionStorage.getItem('bigShotAccount')
				)

				let claimContract = new web3.eth.Contract(
					bigShotClaimAbi,
					contractDetails.claim
				)
				let owner = await claimContract.methods.owner().call()
				balance = web3.utils.fromWei(balance)
				balance = parseFloat(balance).toFixed(5)
				data1 = parseFloat(data1).toFixed(5)

				if (parseFloat(balance) < parseFloat(data1)) {
					toast.warning('Insufficient Funds')
					cb()
				} else {
					let buyToken = await web3.eth.sendTransaction({
						from: sessionStorage.getItem('bigShotAccount'),
						to: owner,
						value: +amount
					})
					if (buyToken.status === true) {
						let data = {
							from_address: sessionStorage
								.getItem('bigShotAccount')
								.toLowerCase(),
							to_address: owner,
							currency: 'BNB',
							network_type: 'BSC',
							spend_amount: data1,
							txn_id: buyToken.transactionHash,
							status: 'success',
							request_amount: data2,
							user_address: ''
						}

						let send = await axios.post(
							CONST.BACKEND_URL + '/ico/buytoken',
							data
						)

						if (send.data.status == 200) {
							toast.success(send.data.message)
							cb(true)
						} else {
							cb()
						}
					} else {
						cb()
					}
				}
			} catch (err) {
				toast.error('Transaction Rejected')
				console.log(err)
				cb()
			}
		}
	} else if (data == 'USDT') {
		if (typeof window.web3 !== undefined) {
			try {
				await ChangeNetwork.Bnbnetwork()
				// let amount = web3.utils.toWei((data1).toString(), 'ether');
				let amount = data1 * 1e18
				await sleep(3000)
				let usdtContract = new web3.eth.Contract(
					usdtAbi,
					contractDetails.USDT
				)

				let claimContract = new web3.eth.Contract(
					bigShotClaimAbi,
					contractDetails.claim
				)

				let owner = await claimContract.methods.owner().call()
				let balance = await usdtContract.methods
					.balanceOf(sessionStorage.getItem('bigShotAccount'))
					.call()
				balance = web3.utils.fromWei(balance)
				balance = parseFloat(balance).toFixed(5)
				data1 = parseFloat(data1).toFixed(5)

				if (parseFloat(balance) < parseFloat(data1)) {
					toast.warning('Insufficient Funds')
					cb()
				} else {
					let buyToken = await usdtContract.methods
						.transfer(owner, amount.toString())
						.send({
							from: sessionStorage.getItem('bigShotAccount')
						})
					if (buyToken.status === true) {
						let data = {
							from_address: sessionStorage
								.getItem('bigShotAccount')
								.toLowerCase(),
							to_address: owner,
							currency: 'USDT',
							network_type: 'BSC',
							spend_amount: data1,
							txn_id: buyToken.transactionHash,
							status: 'success',
							request_amount: data2,
							user_address: ''
						}

						let send = await axios.post(
							CONST.BACKEND_URL + '/ico/buytoken',
							data
						)

						if (send.data.status == 200) {
							toast.success(send.data.message)
							cb(true)
						} else {
							cb()
						}
					} else {
						cb()
					}
				}
			} catch (err) {
				console.log(err)
				toast.error('Transaction Rejected')
				cb()
			}
		}
	} else if (data == 'ETH') {
		if (typeof window.web3 !== undefined) {
			try {
				const response = await axios.get(
					CONST.BACKEND_URL + '/users/getOwner'
				)
				let owner

				if (response.data.status === 200) {
					owner = response.data.owner
				}
				await ChangeNetwork.Ethnetwork()
				// let amount = web3.utils.toWei((data1).toString(), 'ether');
				let amount = data1 * 1e18
				await sleep(3000)
				let balance = await web3.eth.getBalance(
					sessionStorage.getItem('bigShotAccount')
				)
				balance = web3.utils.fromWei(balance)
				balance = parseFloat(balance).toFixed(5)
				data1 = parseFloat(data1).toFixed(5)

				if (parseFloat(balance) < parseFloat(data1)) {
					toast.warning('Insufficient Funds')
					cb()
				} else {
					let buyToken = await web3.eth.sendTransaction({
						from: sessionStorage.getItem('bigShotAccount'),
						to: owner,
						value: Number(amount)
					})
					if (buyToken.status === true) {
						let data = {
							from_address: sessionStorage
								.getItem('bigShotAccount')
								.toLowerCase(),
							to_address: owner,
							currency: 'ETH',
							network_type: 'Ethereum',
							spend_amount: data1,
							txn_id: buyToken.transactionHash,
							status: 'success',
							request_amount: data2,
							user_address: ''
						}

						let send = await axios.post(
							CONST.BACKEND_URL + '/ico/buytoken',
							data
						)

						if (send.data.status == 200) {
							toast.success(send.data.message)
							cb(true)
						} else {
							cb()
						}
					} else {
						cb()
					}
				}
			} catch (err) {
				console.log(err)
				toast.error('Transaction Rejected')
				cb()
			}
		}
	}
}
